import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import TextField from '../../../components/shared/Input/textField';
import emailService from '../../../services/email.service';
import globalContext from '../../../context/globalContext';
import { Student } from '../../../models/Student.model';
import { AlertType } from '../../../models/AlertType.enum';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import './studentEmail.scss';
import { toast } from 'react-toastify';

type EmailPyload = {
  studentId: number;
  schoolId: number;
  school: string;
  to: string;
  subject: string;
  message: string;
  attachments: FileList;
  emailId: number;
  token: string;
};

const StudentEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<EmailPyload>();

  const location = useLocation();
  const { setLoading, showAlert } = useContext(globalContext);
  const [student, setStudent] = useState<Student>({} as Student);
  const [emailId, setEmailId] = useState<number>(0);
  const [schoolId, setSchoolId] = useState<number>(0);
  const [sentSuccess, setSentSuccess] = useState<boolean>(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);

  const loadData = async () => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      try {
        const resp = await emailService.validateEmailToken(token);
        if (resp?.status === HttpStatusCode.Ok) {
          setStudent(resp.data.student);
          setEmailId(resp.data.emailId);
          setSchoolId(resp.data.student.schoolData.id);
          setValue('to', resp.data.student.schoolData?.email || '');
          setValue('school', resp.data.student.schoolData?.name || '');
        } else {
          showAlert('Invalid token', AlertType.Error);
          setIsTokenValid(false);
        }
      } catch (error) {
        setIsTokenValid(false);
        showAlert('Error occurred while validating token', AlertType.Error);
      }
    }

    setLoading(false);
  };

  const handleSend = async (data: EmailPyload) => {
    data.schoolId = student.school;
    data.studentId = student.id;
    data.emailId = emailId;
    data.token = new URLSearchParams(location.search).get('token') || '';

    setLoading(true);
    try {
      const resp = await emailService.sendStudentEmail(data);
      if (resp?.status === HttpStatusCode.Ok) {
        toast.dark('✔️ Email Sent Successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSentSuccess(true);
      } else {
        showAlert('Unexpected Error occurred while sending Email', AlertType.Error);
      }
    } catch (error) {
      showAlert('Error occurred while sending email', AlertType.Error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const getBody = () => {
    return (
      <div className="body">
        <p className="subtitle">Dear {student.firstName + ' ' + student.lastName}.</p>
        <p>Please use the form below to send us a response.</p>
        <div className="container-fluid-mb-3 form-group">
          <div className="row header-row">
            <div className="col-6">
              <TextField errors={errors} register={register} label="School" field="school" type="text" disabled />
            </div>
            <div className="col-6">
              <TextField errors={errors} register={register} label="Email" field="to" type="text" disabled />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <TextField errors={errors} register={register} label="Subject" field="subject" type="text" rules={{ required: true }} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextField
                errors={errors}
                register={register}
                label="Message"
                field="message"
                type="text"
                multiLine={true}
                rows={10}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="email-actions">
            <button className="send btn btn-primary" onClick={handleSubmit(handleSend)}>
              Send Reply
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getSuccesBody = () => {
    return (
      <div className="body">
        <div className="container-fluid-mb-3 form-group">
          <div className="row row-success">
            <div className="col-12">
              <p className="subtitle-sent">Response Sent, Thank you!</p>
              <p>You can now close this window.</p>
              <button className="btn btn-outline-danger" onClick={() => window.close()}>
                Close Window
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getErrorBody = () => {
    return (
      <div className="body">
        <div className="container-fluid-mb-3 form-group">
          <div className="row row-error">
            <div className="col-12">
              <p className="subtitle-sent">Invalid Token</p>
              <p>Your token seems invalid or it expired, please get in contact with the university personnel to request a new one.</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="student-email">
      <div className="school">
        <img className="logo" src={'/static/schools/logos/' + schoolId + '.png'} alt="School logo"></img>
      </div>
      {isTokenValid ? (sentSuccess ? getSuccesBody() : getBody()) : getErrorBody()}
    </div>
  );
};

export default StudentEmail;
