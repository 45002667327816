import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CobaExitCounselingPageProps } from "../coba.exitCounseling.pageProps";
import { Student } from "../../../../../models/Student.model";
import { useForm } from "react-hook-form";
import usePageSignature from "../../../../../hooks/pageSignature";
import { SignedFormStudent } from "../../../../../models/Forms.model";
import commonService from "../../../../../services/common.service";
import TextField from "../../../../../components/shared/Input/textField";
import PhoneField from "../../../../../components/shared/Input/phoneField/phoneField";
import { MASKS } from "../../../../../components/shared/Input/patterns";
import SelectField from "../../../../../components/shared/Input/selectField";
import { COBAFormsPage } from "../../../../../models/Forms.enum";
import Checkbox from "../../../../../components/shared/Input/checkBox";


interface ExtraData {
   firstNameRef1: String;
   lastNameRef1: String;
   middleNameRef1?: String;

   streetRef1: String;
   cityRef1: String;
   stateRef1: String;
   zipCodeRef1: String;
   countryRef1: String;

   phoneNumberRef1: String;
   emailRef1: String;

   firstNameRef2: String;
   lastNameRef2: String;
   middleNameRef2?: String;

   streetRef2: String;
   cityRef2: String;
   stateRef2: String;
   zipCodeRef2: String;
   countryRef2: String;

   phoneNumberRef2: String;
   emailRef2: String;

   checkMaterials: boolean;
   checkRights: boolean;
   checkLoan: boolean;
   studentName: String;
}

interface ExitCounselingFormPage2 {
   student: Student;
   extraData: ExtraData;
}

export const ExitCounselingPage2 = forwardRef(({ data, visible }: CobaExitCounselingPageProps, ref) => {
   const { register, formState: { errors, isValid }, getValues, watch, trigger, control, setValue } = useForm<ExitCounselingFormPage2>();

   const { saveForm, getSignature, validateForm, setIsCustomValid, signatureData, setRequiredCustomValidation, getAcknowledge } = usePageSignature(data, true);

   const [states, setStates] = useState<any[]>([]);
   const [student, setStudent] = useState<SignedFormStudent>(null!);

   const loadStates = async () => {
      const states = await commonService.getStates();
      setStates(states);
   }

   useImperativeHandle(ref, () => ({
      async saveData() {
         let data: ExtraData = getValues().extraData;
         console.log(data);
         return await saveForm(COBAFormsPage.ExitConseling2, true, data);
      }
   }));

   const formValues = watch();

   useEffect(() => {
      setIsCustomValid(isValid);
   }, [formValues]);

   useEffect(() => {
      manualFormValidation();
   }, [signatureData]);

   const manualFormValidation = async () => {
      if (signatureData) {
         const isItValidNow = await trigger();
         if (!isItValidNow) {
            setInterval(() => {
               trigger();
            }, 1000);
         }
      }
   };

   useEffect(() => {
      setRequiredCustomValidation(true); // This is to make sure that the custom validation is required
      if (!visible) return;
      
      let studentName = `${data.formData.student.firstName} ${data.formData.student.middleName ? data.formData.student.middleName : ''} ${data.formData.student.lastName}`;
      setValue('extraData.studentName', studentName);

      setStudent(data.formData.student);

   }, [visible]);

   useEffect(() => {
      if (visible) {
         setTimeout(() => {
            window.scrollTo(0, 0);
            validateForm();
         }, 500);
         loadStates();
      }
   }, [visible]);

   if (!visible) return null;

   return (
      <div className="exitCounseling-page2">
         <div className="row">
            <h3 className="title">Student Contact Information and Acknowledgment(Cont.)</h3>
         </div>
         <div className="row">
            <h4 className="subtitle">
               References: You must list two persons with U.S. addresses<br /> different from yours who will know your whereabouts fot a least three years.
            </h4>
         </div>
         <div className="row">
            <hr />
            <h4 className="subtitle">Reference 1</h4>
         </div>
         <div className="row">
            <div className="col-4">
               <TextField
                  label="Last Name"
                  field="extraData.lastNameRef1"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-4">
               <TextField
                  label="First Name"
                  field="extraData.firstNameRef1"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-4">
               <TextField
                  label="Middle Name(Optional)"
                  field="extraData.middleNameRef1"
                  type="text"
                  register={register}
                  errors={errors}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-12">
               <TextField
                  label="Permanent Home Street Address"
                  field="extraData.streetRef1"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-3">
               <TextField
                  label="City"
                  field="extraData.cityRef1"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-3">
               <SelectField
                  label="State"
                  field="extraData.stateRef1"
                  errors={errors}
                  control={control}
                  rules={{ required: true }}
                  labelKey="name"
                  valueKey="id"
                  options={states.map(s => ({ id: s.name, name: s.name }))}
               />
            </div>
            <div className="col-3">
               <TextField
                  label="Zip/Postal Code"
                  field="extraData.zipCodeRef1"
                  type="text"
                  register={register}
                  errors={errors}
                  mask={MASKS.zipCodeMask}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-3">
               <TextField
                  label="Country"
                  field="extraData.countryRef1"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-6">
               <PhoneField
                  label="Phone Number"
                  field="extraData.phoneNumberRef1"
                  parentClass="form-group"
                  type="text"
                  placeholder="(xxx) xxx-xxxx"
                  register={register}
                  errors={errors}
                  control={control}
                  rules={{ required: true, minLength: 10 }}
               />
            </div>
            <div className="col-6">
               <TextField
                  label="Email"
                  field="extraData.emailRef1"
                  type="email"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <hr />
            <h4 className="subtitle">Reference 2</h4>
         </div>
         <div className="row">
            <div className="col-4">
               <TextField
                  label="Last Name"
                  field="extraData.lastNameRef2"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-4">
               <TextField
                  label="First Name"
                  field="extraData.firstNameRef2"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-4">
               <TextField
                  label="Middle Name(Optional)"
                  field="extraData.middleNameRef2"
                  type="text"
                  register={register}
                  errors={errors}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-12">
               <TextField
                  label="Permanent Home Street Address"
                  field="extraData.streetRef2"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-3">
               <TextField
                  label="City"
                  field="extraData.cityRef2"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-3">
               <SelectField
                  label="State"
                  field="extraData.stateRef2"
                  errors={errors}
                  control={control}
                  rules={{ required: true }}
                  labelKey="name"
                  valueKey="id"
                  options={states.map(s => ({ id: s.name, name: s.name }))}
               />
            </div>
            <div className="col-3">
               <TextField
                  label="Zip/Postal Code"
                  field="extraData.zipCodeRef2"
                  type="text"
                  register={register}
                  errors={errors}
                  mask={MASKS.zipCodeMask}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-3">
               <TextField
                  label="Country"
                  field="extraData.countryRef2"
                  type="text"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-6">
               <PhoneField
                  label="Phone Number"
                  field="extraData.phoneNumberRef2"
                  parentClass="form-group"
                  type="text"
                  placeholder="(xxx) xxx-xxxx"
                  register={register}
                  errors={errors}
                  control={control}
                  rules={{ required: true, minLength: 10 }}
               />
            </div>
            <div className="col-6">
               <TextField
                  label="Email"
                  field="extraData.emailRef2"
                  type="email"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <hr />
            <h3>Student Acknowledgment</h3>
         </div>
         <div className="row">
            <div className="col-12">
               <Checkbox
                  label="I have received exit counseling materials for Direct Loan borrowers."
                  field="extraData.checkMaterials"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-12">
               <Checkbox
                  label="I have read and I understand my rights and responsibilities as a borrower."
                  field="extraData.checkRights"
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
               />
            </div>
            <div className="col-12">
               <Checkbox
                  label="I understand that I have a loan from the federal government that must be repaid."
                  field="extraData.checkLoan"
                  register={register}
                  errors={errors}
                  // rules={{ required: true }}
               />
            </div>
         </div>
         <div className="row">
            <div className="col-12">
               <TextField
                  label='Student Name'
                  field='extraData.studentName'
                  type='text'
                  register={register}
                  errors={errors}
                  rules={{ required: true }}
                  disabled
               />
            </div>
         </div>
         <div className="relative-position">
            {getSignature()}
         </div>
      </div>
   )
})