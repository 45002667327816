// this enum should match helpers.py enum
export enum SignedFormStatus {
	New = 0,
	Sent = 1,
	InProgress = 2,
	Completed = 3,
	Closed = 4,
}

// this enum should match helpers.py enum
export enum SignedFormKey {
	NONE = "",
	DISCLOSURE = "disclosure",
	FACTSHEET = "factsheet",
	VIB = "vib",
	ENROLLMENT_AGREEMENT = "enroll_agreement",
	ENROLLMENT_AGREEMENT_SPANISH = "enroll_agreement_spanish",
	ENROLLMENT_AGREEMENT_SPANISH_2 = "enroll_agreement_spanish_2",
	GENQUESTIONAIRE = "general_questionaire",
	MASQUESTIONAIRE = "massage_questionaire",
	TRANSCRIPTS_COMMITMENT = "transcripts_commitment",
	FINANCIAL_AID = "financial_aid",
	HYBRID_STATEMENT = "hybrid_statement", // Hybrid Disclosure is Hybrid Statement
	BENEFIT_CODING = "benefit_coding",
	VA_EDUCATION_BENEFIT_CERTIFICATION = "va_education_benefit",
	TRANSCRIPT_EVALUATION_REQUEST = "transcript_evaluation_request",
	ORIENTATION_FORM = "orientation_form",
	IPAD_DISCLOSURE = "ipad_disclosure",
	ADDENDUM = "addendum",
	EXIT_COUNSELING = "exit_counseling",
}

// this enums should match BE helpers.py enums
export enum SDGKUFormsPage {
	Agreement1 = "SDGKU_EA_P1",
	Agreement2 = "SDGKU_EA_P2",
	Agreement3 = "SDGKU_EA_P3",
	Agreement4 = "SDGKU_EA_P4",
	Agreement5 = "SDGKU_EA_P5",
	Agreement6 = "SDGKU_EA_P6",
	Agreement7 = "SDGKU_EA_P7",
	Agreement8 = "SDGKU_EA_P8",
	VIB = "SDGKU_VIB",
	Factsheet = "SDGKU_FS",
	Disclosure = "SDGKU_Dis",
	TRANSCRIPTS_COMMITMENT = "SDGKU_trans",
	HYBRID_STATEMENT = "SDGKU_HybridStatement", // Hybrid Disclosure is Hybrid Statement
	BENEFIT_CODING = "SDGKU_benefit_coding",
	Va_education1 = "SDGKU_VAE_P1",
	Va_education2 = "SDGKU_VAE_P2",
	Transcript_evaluation = "SDGKU_trans_eval",
	Addendum = "SDGKU_addendum",
}

export enum COBAFormsPage {
	Disclosure1 = "CB_Dis_P1",
	Disclosure2 = "CB_Dis_P2",
	Disclosure3 = "CB_Dis_P3",
	Disclosure4 = "CB_Dis_P4",
	Disclosure5 = "CB_Dis_P5",
	DisclosureMindTap = "CB_Dis_MT",
	Factsheet = "CB_FS",
	FinancialAid1 = "CB_FA_P1",
	FinancialAid2 = "CB_FA_P2",
	FinancialAid3 = "CB_FA_P3",
	FinancialAid4 = "CB_FA_P4",
	FinancialAid5 = "CB_FA_P5",
	FinancialAid6 = "CB_FA_P6",
	OrientationForm = "CB_Orientation",
	ExitConseling1 = "CB_EC_P1",
	ExitConseling2 = "CB_EC_P2",
}

export enum PAFormsPage {
	Questionaire = "PA_QA",
}

export enum EnrollmentAgreementFees {
	Refund_7 = "ea_cancel_7",
	Refund_28 = "ea_cancel_28",
	Refund_56 = "ea_cancel_56",
	Refund_84 = "ea_cancel_84",
	Refund_after = "ea_cancel_after",

	Fee_App = "ea_fee_app",
	Fee_Reg = "ea_fee_reg",
	Fee_Tuition = "ea_fee_tuition",
	Fee_Tuition_2 = "ea_fee_tuition_2",

	Fee_Tuition_Total = "ea_fee_tuition_total",
	Fee_Other = "ea_fee_other",
	Fee_Graduation = "ea_fee_graduation",
	Fee_Tuiton_Waiver = "ea_fee_waiver",

	Fee_Labs = "ea_fee_labs",
	Fee_Text = "ea_fee_text",
	Fee_Uniforms = "ea_fee_uniform",
	Fee_UTranscript = "ea_fee_utranscript",
	Fee_Transcript = "ea_fee_transcript",
	Fee_Diploma = "ea_fee_diploma",
	Fee_Check = "ea_fee_check",
	Fee_STRF = "ea_fee_strf",

	Fee_Residence = "ea_fee_residence",
	Fee_Equipment	= "ea_fee_equipment",
	Fee_Tutoring = "ea_fee_tutoring",
	Fee_assessment = "ea_fee_assessment",
	Fee_Transfer = "ea_fee_transfer",

	Charge_Attendance = "ea_charge_attendance",
	Charge_Program = "ea_charge_program",
	Charge_Enrrollment = "ea_charge_enrollment",
}

export enum VIBFees {
	Application = "vib_fee_app",
	Registration = "vib_fee_reg",
	Tuition = "vib_fee_tuition",
	Graduation = "vib_fee_graduation",
	STRF = "vib_fee_strf",
	Other = "vib_fee_other",
}

export enum FactsheetFacts {
	Graduation_Facts = "fs_graduation_facts",
	Completing_Facts = "fs_completing_facts",
	Placement_Facts = "fs_placement_facts",
	Gainfully_Facts = "fs_gainfully_facts",
	Pass_Facts = "fs_pass_facts",
	Salary_Facts = "fs_salary_facts",
	Costs_Facts = "fs_costs_facts",
	Federal_Facts = "fs_federal_facts",
}
