import React, { useState } from "react";
import Modal from "../components/modal/modal";
import { Student } from "../models/Student.model";
import AddendumPrev from "../components/prevForms/addendumPrevFrom";
import { toast } from "react-toastify";


export interface PrevFormProps {
	student: Student;
	closeModal: () => void;
	processResponse: (response: any) => void;
}

interface PrevFormsReturn {
	openPrevDialogForm: (formKey: string, title: string, onAccept: () => {}) => void;
	getPrevDialogForm: () => JSX.Element;
	setStudentPrev: (prop: Student) => void;
}

const usePrevForms = (): PrevFormsReturn => {
	const [__student, __setStudent] = useState<Student>(new Student());
	const [__isVisible, __setIsVisible] = useState<boolean>(false);
	const [__formKey, __setFormKey] = useState("");
	const [__title, __setTitle] = useState("");

	const closeModal = () => {
		__setIsVisible(false);
	}

	const processResponse = (response: any) => {
		if (response) {
		  toast.dark('Email sent. You will recieve a copy of it.', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
		  });
		} else {
		  toast.error('There was a problem sending the email.', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
		  });
		}
	  };

	// Keys of this dict (__forms dict) must match with SignedFormKey values
	// Create a new form component on frontend/src/components/prevForms/
	const __forms: { [key: string]: JSX.Element } = {
		addendum: <AddendumPrev student={__student} closeModal={closeModal} processResponse={processResponse}  />,
	};

	const __getBody = (formkey: string) => {
		const body = __forms[formkey];
		return body;
	}

	const openPrevDialogForm = (formKey: string, title: string, onAccept: () => {}) => {
		__setFormKey(formKey);
		__setTitle(title);
		__setIsVisible(true);
	}

	const setStudent = (student: Student) => {
		__setStudent(student);
	}

	const getPrevDialogForm = () => {
		return (
			<Modal
				visible={__isVisible}
				hideFooter={true}
				size="lg"
				title={"Previous to send " + __title + " form."}
				body={__isVisible ? __getBody(__formKey) : <></>}
				onClose={closeModal}
			></Modal>
		);
	}

	return {
		openPrevDialogForm,
		getPrevDialogForm,
		setStudentPrev: setStudent,
	}
}

export default usePrevForms;
