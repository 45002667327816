import * as React from "react";
import authService from "../../../services/auth.service";

class UserProfile extends React.Component<any, any> {
  state = {};
  render() {
    return <div className="page profile-page">your info here</div>;
  }

  async componentDidMount() {
    let me = await authService.me();
    console.log(me);
  }
}

export default UserProfile;
