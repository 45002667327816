import html2canvas from 'html2canvas';
import { Sheet } from '@fortune-sheet/core';
import { useContext, useEffect, useState } from 'react';
import Modal from '../../modal/modal';
import globalContext from '../../../context/globalContext';
import { School } from '../../../models/School.model';
import Select from 'react-select';
import TextField from '../../shared/Input/textField';
import SelectField from '../../shared/Input/selectField';
import { useForm } from 'react-hook-form';
import { FinancialEstimateTemplate } from '../../../models/FinancialEstimate.model';
import studentEstimateService from '../../../services/studentEstimate.service';
import useToastFromAxiosResponse from '../../../hooks/toastFromAxiosResponse';
import { SchoolID } from '../../../models/Enums';
import { Program } from '../../../models/Program.model';
import programsService from '../../../services/programs.service';

export interface IAdminControlsProps {
  studentId?: string | number;
  workbookRef: any;
  sheets: Sheet[];
  printComponentRef: any;
  setSheets?: React.Dispatch<React.SetStateAction<Sheet[]>>;
  onBeforePrint?: () => void;
  onAfterPrint?: () => void;
}

const AdminControls: React.FC<IAdminControlsProps> = ({ printComponentRef, workbookRef, sheets, setSheets }) => {
  const [modalSaveVisible, setModalSaveVisible] = useState(false);
  const [schools, setSchools] = useState<School[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);

  const [templates, setTemplates] = useState<FinancialEstimateTemplate[]>([]);
  const [selecctedTemplateSchool, setSelectedTemplateSchool] = useState<number>(0);
  const [selecctedTemplateProgram, setSelectedTemplateProgram] = useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = useState<FinancialEstimateTemplate | null>(null);

  const showToast = useToastFromAxiosResponse();
  const { loaders } = useContext(globalContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<FinancialEstimateTemplate>();

  const schoolWatch = watch('school');

  // loading data and presentation logic
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let mySchools = await loaders.schools();
    setSchools(mySchools);

    let allProgramsReq = programsService.getPrograms();
    let allTemplatesReq = studentEstimateService.getAllFATemplates();

    const [allPrograms, allTemplates] = await Promise.all([allProgramsReq, allTemplatesReq]);
    setTemplates(allTemplates?.data || []);
    setPrograms(allPrograms || []);
  };

  const getSchoolOptions = () => {
    return schools?.map((school) => ({ value: school.id, label: school.shortName }));
  };

  const getSchoolPrograms = () => {
    return programs?.filter((x) => x.schoolId === schoolWatch).map((program) => ({ value: program.id, label: program.name }));
  };

  const getProgramName = (id: number) => {
    let program = programs.find((x) => x.id === id);
    return program?.shortName || program?.name || '';
  };

  const getTemplateSchools = () => {
    return schools?.map((school) => ({ value: school.id, label: school.shortName }));
  };

  const getTemplatePrograms = () => {
    return programs?.filter((x) => x.schoolId === selecctedTemplateSchool).map((program) => ({ value: program.id, label: program.name }));
  };

  const getTemplateOptions = () => {
    return templates?.filter(x => x.programId === selecctedTemplateProgram).map((template) => ({ value: template.id, label: template.name }));
  };

  const clearSheets = () => {
    for (let i = 0; i < sheets.length; i++) {
      workbookRef.current?.deleteSheet(sheets[i].name);
    }
  };

  const onTemplateSchoolSelected = (option: any) => {
    setSelectedTemplateSchool(option.value);
  };

  const onTemplateProgramSelected = (option: any) => {
    setSelectedTemplateProgram(option.value);
  };

  const onTemplateSelected = (option: any) => {
    let template = templates.find((t) => t.id === option.value);
    if (template) {
      clearSheets();
      setSelectedTemplate(template);
      // set workbook to spreadsheet
      let wb = JSON.parse(template.template);
      setSheets?.(wb);
      workbookRef.current?.updateSheet(wb);
    }
  };

  const handleScreenshot = () => {
    html2canvas(printComponentRef.current).then((canvas) => {
      const imageData = canvas.toDataURL('image/png');
      console.log(imageData);
    });
  };

  const handleSave = () => {
    console.log('saving', sheets);
    setModalSaveVisible(true);
  };

  const handleLoad = () => {
    const data = localStorage.getItem('workbookData');
    if (data) {
      const wb = JSON.parse(data);
      setSheets?.(wb);
      workbookRef.current?.updateSheet(wb);
      console.log('loading', wb);
    } else {
      console.log('no data found');
    }
  };

  const handleConsole = () => {
    console.log(JSON.stringify(sheets));
    // parseWorkbookData(workbookData);
  };

  const handleSaveTemplate = async (form: FinancialEstimateTemplate) => {
    form.template = JSON.stringify(sheets);
    const res = await studentEstimateService.createFATemplate(form);
    console.log(res?.data);
  };

  const handleUpdateTemplate = async () => {
    if (selectedTemplate) {
      selectedTemplate.template = JSON.stringify(sheets);
      const res = await studentEstimateService.updateFATemplate(selectedTemplate);
      showToast(res, { successMessage: ' ✔️ Template updated successfully' });
    }
  };

  return (
    <div className="admin-controls no-print">
      <Modal
        visible={modalSaveVisible}
        cancelButtonText="Cancel"
        mainButtonText="Save template"
        mainButtonClass="btn-primary"
        title="Save Financial Estimate Template"
        onCancel={() => {
          setModalSaveVisible(false);
        }}
        onClose={() => {
          setModalSaveVisible(false);
        }}
        onAccept={handleSubmit((d) => handleSaveTemplate(d))}
      >
        <div className="save-template-modal-content">
          <TextField parentClass="form-group" field="name" type="text" label="Template Name" register={register} errors={errors} rules={{ required: true }} />

          <SelectField field="school" label="School" errors={errors} control={control} rules={{ required: true }} options={getSchoolOptions()} />

          <SelectField field="program_id" label="Program" errors={errors} control={control} rules={{ required: true }} options={getSchoolPrograms()} />
        </div>
      </Modal>

      {/* <button className="btn btn-sm btn-outline-dark mr-2" onClick={handleScreenshot}>
        Save image
      </button> */}
      <h3 className='admin-head'>Admin</h3>
      <button className="btn btn-sm btn-outline-dark mr-2" onClick={handleSave}>
        Save New
      </button>
      <button className="btn btn-sm btn-outline-dark mr-2" onClick={handleUpdateTemplate}>
        Save Changes
      </button>
      {/* <button className="btn btn-sm btn-outline-dark mr-2" onClick={handleLoad}>
        Load
      </button> */}
      <button className="btn btn-sm btn-outline-dark mr-2" onClick={handleConsole}>
        Console
      </button>

      <div className="ml-2 menu-option">      
        <Select placeholder="School" className="template-select" options={getTemplateSchools()} onChange={onTemplateSchoolSelected} />
      </div>

      <div className="menu-option">
        <Select placeholder="Program" className="template-select" options={getTemplatePrograms()} onChange={onTemplateProgramSelected} />
      </div>

      <div className="menu-option">
        <Select className="template-select" options={getTemplateOptions()} onChange={onTemplateSelected} />
      </div>
    </div>
  );
};

export default AdminControls;
