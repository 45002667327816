import { User as IconUser, AtSign as IconEmail, Phone as IconPhone, MapPin as IconMapPin, Figma as IconFigma, BarChart2 as IconStatus, Edit3 as IconEdit, Flag as IconFlag, Hash as HashIcon } from 'react-feather';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { useState, useEffect, useContext } from 'react';
import globalContext from '../../../context/globalContext';

import studentService from '../../../services/student.service';
import programService from '../../../services/programs.service';

import Select from 'react-select';

import './studentCard.scss';
import { StudentStatus, StudentStatusName } from '../../../models/Enums';
import { Program, ProgramStartDate, StudentProgram } from '../../../models/Program.model';
import { CacheConstants } from '../../../services/constants.service';
import moment from 'moment';

const StudentCard = (props: any) => {
  // Program variables  
  const [programOptions, setProgramOptions] = useState() as any[];
  const [editingProgram, setEditingProgram] = useState(false) as any;

  // Status variables
  const [statusOptions, setStatusOptions] = useState() as any[];
  const [selectedStatus, setSelectedStatus] = useState() as any;
  const [editingStatus, setEditingStatus] = useState(false) as any;

  // Start Date variables
  const [startDate, setStartDate] = useState() as any;
  const [startDatesOptions, setStartDatesOptions] = useState<ProgramStartDate[]>();
  const [editingStartDate, setEditingStartDate] = useState(false);

  const { setLoading, clearCacheKey } = useContext(globalContext);

  const loadProgramOptions = async () => {
    if (!props.student.school) return;
    let response = await programService.getProgramsBySchool(props.student.school);
    if (response?.status === HttpStatusCode.Ok) {
      let programs = response.data.filter((program: Program) => program.enabled === true);
      let programOptions = programs.map((program: Program) => {
        return { value: program.id, label: program.name };
      });
      setProgramOptions(programOptions.sort((a, b) => a.label.localeCompare(b.label)));
    }
  }

  const onProgramChange = async (option: any) => {
    setEditingProgram(false);
    props.updateProgram(option.value);
  }

  const loadStatusOptions = () => {
    let allowedStatus = [StudentStatus.APPLICATION_REQUEST, StudentStatus.APPLICATION_ALLOWED, StudentStatus.ENROLLED, StudentStatus.ACTIVE, StudentStatus.GRADUATED, StudentStatus.CANCELLED, StudentStatus.WITHDRAWN, StudentStatus.LEAVE_OF_ABSENCE];
    let statusOptions = [] as any[];
    for (let i = 0; i < allowedStatus.length; i++) {
      statusOptions.push({ value: allowedStatus[i], label: StudentStatusName(allowedStatus[i]) });
    }
    setStatusOptions(statusOptions.sort((a, b) => a.label.localeCompare(b.label)));
  };

  const getStudentStatus = () => {
    if (selectedStatus) {
      return { value: selectedStatus, label: StudentStatusName(selectedStatus) };
    } else {
      return { value: props.student.status, label: StudentStatusName(props.student.status) };
    }
  };

  const getStudentStatusText = () => {
    let status = getStudentStatus()?.label
    return <label className="mg-0 pd-0 pr-2">{status ? status : <i><u>missing</u></i>}</label>
  };

  const onStatusChange = async (option: any) => {
    setEditingStatus(false);
    setLoading(true);

    let resp = await studentService.partialUpdateStudent(props.student.id, { status: option.value });

    if (resp?.status === HttpStatusCode.Ok) {
      setSelectedStatus(option.value);
    }

    setLoading(false);
    clearCacheKey(CacheConstants.STUDENTS);
  };

  const loadStartDates = async () => {
    let response = await programService.getStartDatesBySchool(props.student.school);

    if (response) {
      const pivot = moment().add(-1, "months");

      const startDates = response
        .filter((startDateT: ProgramStartDate) => {
          if (startDateT.programId === props.program?.id) return startDateT;
        })
        .filter((startDateT: ProgramStartDate) => {
          if (moment(startDateT.startDate).isAfter(pivot)) return startDateT;
        })
        .slice(0, 3)
        .map((startDateT: ProgramStartDate) => {
          let startDate = { value: startDateT.startDate, label: startDateT.startDate };
          if (startDateT.startDate === props.student.startDate) setStartDate(startDate); // Set the selected start date
          return startDate;
        });

      setStartDatesOptions(startDates);
    }
  }

  const getStudentStartDateText = () => {
    return <label className="mg-0 pd-0 pr-2">{startDate?.value}</label>
  }

  const onStartDateChange = async (option: any) => {
    setEditingStartDate(false);
    let startDateSelected = moment(option?.value).format('YYYY-MM-DD');
    let resp = await studentService.updateStudentStartDate(props.student.id, startDateSelected);

    if (resp?.status === HttpStatusCode.Ok) {
      setStartDate(option);
      clearCacheKey(CacheConstants.STUDENTS);
    }

  }

  useEffect(() => {
    loadProgramOptions();
  }, [props.student]);

  useEffect(() => {
    if (props.program) {
      loadStartDates();
    }
  }, [props.program]);

  useEffect(() => {
    loadStatusOptions();
  }, []);

  return (
    <div className="student-card">
      <div className="card">
        <div className="card-header">
          <h6 className="mg-b-0">Student Information</h6>
        </div>
        <div className="card-body">
          <label>
              <HashIcon className="icon" />
              Id: <b>{props.student?.id}</b>
            </label>
          <h4 className="tx-normal tx-rubik tx-18 tx-spacing--1 mg-b-5">
            <IconUser className="icon" />
            {props.student.fullName?.()}
          </h4>
          {props.student?.preferredPronoun && (
            <label>
              <IconFigma className="icon" />
              {props.student?.preferredPronoun}
            </label>
          )}
          <label>
            <IconEmail className="icon" />
            {props.student?.email}
          </label>
          <label>
            <IconPhone className="icon" />
            {props.student?.phone}
          </label>
          <label>
            <IconMapPin className="icon" />
            {props.student.fullAddress?.()}
          </label>

          {props.program?.program ? (
            <div className="d-flex flex-wrap align-items-center">
              <IconMapPin className="icon" />
              <label className="mg-0 pd-0 pr-2 label">Program: </label>
              {!editingProgram ? (
                <>
                  <button className="btn btn-sm btn-link btn-icon" onClick={() => setEditingProgram(true)}>
                    Edit
                  </button>
                  <label className='program-name'>{props.program?.program}</label>
                </>
              ) : (
                <Select
                  className="flex-grow-1 program-name"
                  value={{ value: props.program?.id, label: props.program?.program }}
                  placeholder="Program"
                  onChange={(val: any) => {
                    onProgramChange(val);
                  }}
                  options={programOptions}
                />
              )}
            </div>
          ) : null}

          <div className="d-flex align-items-center">
            <IconFlag className="icon" />
            <label className="mg-0 pd-0 pr-2 label">Start Date: </label>
            {!editingStartDate ? (
              <>
                {getStudentStartDateText()}
                <button className="btn btn-sm btn-link btn-icon" onClick={() => setEditingStartDate(true)}>
                  Edit
                </button>
              </>
            ) : (
              <Select
                className="flex-grow-1"
                value={startDate}
                placeholder="Start Date"
                onChange={(val: any) => {
                  onStartDateChange(val);
                }}
                options={startDatesOptions}
              />
            )}
          </div>

          <div className="d-flex align-items-center">
            <IconStatus className="icon" />
            <label className="mg-0 pd-0 pr-2 label">Status: </label>
            {!editingStatus ? (
              <>
                {getStudentStatusText()}
                <button className="btn btn-sm btn-link btn-icon" onClick={() => setEditingStatus(true)}>
                  Edit
                </button>
              </>
            ) : (
              <Select
                className="flex-grow-1"
                value={getStudentStatus()}
                placeholder="Status"
                onChange={(val: any) => {
                  onStatusChange(val);
                }}
                options={statusOptions}
              />

            )}
          </div>

        </div>
      </div>
    </div>
  );
};

export default StudentCard;
