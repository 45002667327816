import http from "./http-common";
import { Document } from "../models/Document.model";

class DocumentService {
	async getDocumentTypes() {
		try {
			let response = await http.get(`/document_types/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading document types");
			return undefined;
		}
	}

	async getDocumentsByStudent(studentId: string | number) {
		try {
			let response = await http.get(`/documents/student/${studentId}/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while loading student documents");
			return undefined;
		}
	}

	async zipStudentDocuments(studentId: string | number, payload: any) {
		try {
			let response = await http.post(`/documents/${studentId}/zip_documents/`, payload);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while Ziping documents");
			return undefined;
		}
	}

	async uploadStudentDocument(doc: Document) {
		try {
			let formData = new FormData();
			formData.append("file", doc.file);
			formData.append("file_name", doc.fileName);
			formData.append("file_size", doc.fileSize.toString());
			formData.append("status", doc.status.toString());
			formData.append("student", doc.student.toString());
			formData.append("upload_type", doc.uploadType.toString());
			doc.documentType && formData.append("document_type", doc.documentType.toString());
			doc.documentTemplate && formData.append("document_template", doc.documentTemplate.toString());

			let response = await http.post(`/documents/`, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while uploading document");
			return undefined;
		}
	}

	async emailStudentDocuments(emailRequest: any) {
		try {
			let data = {
				student_id: emailRequest.studentId,
				email_to: emailRequest.to,
				subject: emailRequest.subject,
				message: emailRequest.message,
				files: emailRequest.files,
			};
			let res = await http.patch("/documents/email", data);
			return res;
		} catch (error) {
			http.fa360.displayError("Unexpected error while sending documents");
			return undefined;
		}
	}

	async deactivateDocument(documentId: string | number) {
		try {
			let response = await http.delete(`/documents/${documentId}/deactivate/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while removing document");
			return undefined;
		}
	}

	async approveDocument(documentId: number) {
		try {
			let response = await http.patch(`/documents/${documentId}/approve/`);
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while approving document");
			return undefined;
		}
	}

	async changeDocumentType(documentId: number, documentTypeId: number) {
		try {
			let response = await http.patch(`/documents/${documentId}/`, { id: documentId, document_type: documentTypeId });
			return response;
		} catch (error) {
			http.fa360.displayError("Unexpected error while changing document type");
			return undefined;
		}
	}
}

export default new DocumentService();
