import { forwardRef, useEffect, useImperativeHandle } from "react"
import { CobaFinancialAidPageProps } from "../cobaFinancialAidPageProps";
import usePageSignature from "../../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../../models/Forms.enum";

export const FinancialAidPage3 = forwardRef(({ data, visible }: CobaFinancialAidPageProps, ref) => {
    const { saveForm, getSignature, getAcknowledge } = usePageSignature(data, true);

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(COBAFormsPage.FinancialAid3)
        },
    }));


    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="financialAid-page3">
            <div className="title">
                <h3>MANAGE LOANS - FEDERAL STUDENT AID</h3>
            </div>
            <div className="subtitle">
                <h6>(https://studentaid.gov)</h6>
            </div>

            <p className="question">What is Feredal Student Aid (FSA)? </p>
            <p>The U.S. Department of Education's Office of Federal Student Aid (FSA). It contains data from Academy’s,
                agencies that guarantee loans, the Direct Loan program, and other U.S. Department of Education programs.
                The FSA website provides a centralized, integrated view of Title IV loans and grants that are tracked through
                their entire cycle from aid approval, disbursements, and repayments.</p>
            <p>The FSA website provides Student Access website is available 24 hours a day, 7 days a week.</p>

            <p className="question">What information is available to me through the FSA website?</p>
            <p>You can use the website to make inquiries about your Title IV loans and/or grants. The site displays
                information on loan and/or grant amounts, outstanding balances, loan statuses, and disbursements.</p>

            <p className="question">What information do I need to use the FSA website?</p>
            <p>In order to use the FSA website, you will need to provide your FSA ID username and password.</p>

            <p className="question">How secure is the FSA website</p>
            <p>The unique combination of FSA ID username and password needed to access the FSA</p>

            <p className="question">What assistance is available when using the FSA website?</p>
            <ul>
                <li>The Federal Student Aid Information Center at 1-800-4-FED-AID (1-800-433-3243) is available 8AM to
                    12 PM (Eastern Time), Monday through Friday, except for certain federal holidays. June 19 and 20, in
                    observance of Juneteenth; the day after Thanksgiving; and Dec. 24 and 26. Open on Martin Luther King
                    Jr. Day and President’s Day.</li>
                <li>Access to the Federal Student Aid Information Center Help page is available in the ‘Contact US’ menu.</li>
            </ul>

            <p className="question">How to review my records?</p>
            <p>To review your financial aid history and repayment plan options go to https://studentaid.gov:</p>
            <ul>
                <li>Enter your username ans password</li>
                <li>and select login if you already have an FSA ID;</li>
                <li>If you do not have an FSA ID, select the Create An FSA ID tab.</li>
                <li>You are also able to log into your account to view your</li>
            </ul>

            <p>Acknowledgment</p>
            <p>By signing below, I acknowledge receiving information about the Federal Student Aid (FSA). I also confirm
                that I have been given the information on how to log into the system to view my records.</p>

            <div className="row acknowledge">
                {getAcknowledge("ACK-P3","I acknowledge receiving information about the Federal Student Aid (FSA).")}
            </div>

            <div>
                {getSignature()}
            </div>
        </div>
    )
});