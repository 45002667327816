import { FunctionComponent } from "react";

import "./actionIcon.scss";
import ReactTooltip from "react-tooltip";

interface ActionIconProps {
  icon: any;
  label?: string;
  color?: string;
  tooltip?: string;
  extraClass?: string;
  onClick?: () => void;
}

const ActionIcon: FunctionComponent<ActionIconProps> = (props) => {
  return (
    <div onClick={props.onClick} className={(props.extraClass || "") + " action-icon " + props.color}>
      {props.icon}
      {props.label ? <label>{props.label}</label> : null}
    </div>
  );
};

export default ActionIcon;
// data-tip={props.tooltip}
