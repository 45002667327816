import { forwardRef, useEffect, useImperativeHandle } from "react";
import { CobaFinancialAidPageProps } from "../cobaFinancialAidPageProps";
import usePageSignature from "../../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../../models/Forms.enum";

export const FinancialAidPage6 = forwardRef(({data, visible}:CobaFinancialAidPageProps, ref) => {
    const { saveForm, getSignature, getAcknowledge } = usePageSignature(data, true);

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(COBAFormsPage.FinancialAid6, true)
        },
    }));

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="financialAid-page6">
            <p>Text messaging will not be used for:</p>
            <ul>
                <li>general information to large populations i.e., "Fall classes start Monday!".</li>
                <li>repeat reminders of messages already circulated; and</li>
                <li>personal matters e.g., items for sales, farewell messages.</li>
            </ul>

            <p>All messages will be tagged with the appropriate identifier e.g., the Academy, administrative office so that the recipients of
                the text can see immediately where it originated. Authorized officials do not need prior approval to send periodic messages.</p>

            <p>Messages should be no longer than 160 characters and should address the student directly, i.e., as 'you'. They should include
                essential points plus the instruction: 'check your email for full details' or 'full details to follow by internal post'.</p>

            <p>When possible, abbreviations and text lingo should be kept to a minimum.</p>

            <p>All text messaging support services, or software applications must be approved by an appropriate Academy Director.</p>

            <p className="subtitle">DATA DISCLOSURE</p>
            <p>Coba will not disclose the Information to any third parties, except:</p>

            <ul>
                <li>When a third-party designate is utilized to provide the Service or other services offered or administered by the
                    Academy.</li>
                <li>To the extent that it is required to do so by law in connection with any legal proceedings or prospective legal
                    proceedings;</li>
                <li>To establish, exercise, or defend its legal rights.</li>
            </ul>

            <p>Coba will take reasonable technical and organizational precautions to prevent the loss or misuse of information. The Coba
                will take reasonable steps to verify that the privacy policies and/or practices of its third party designates are comparable to
                its own. Coba is not responsible for privacy policies and/or practices of any third parties that it does not explicitly designate.</p>

            <p className="subtitle">TEXT MESSAGING POLICY UPDATES</p>
            <p>Coba may make changes to this policy to comply with necessary legal and regulatory requirements, or in instances where
                Coba upgrades or alters its technological services. The Academy recommends users review its policies occasionally to
                familiarize themselves with any such changes or alterations.</p>

            <p className="subtitle">COBA'S STUDENT PORTAL</p>
            <p>The student portal is a resource that the Academy uses to communicate financial aid, education, and business office
                communication. This is a secure way for students to have 24/7 access on a secure site.</p>

            <p>I have read and acknowledged receipt of these policies. I understand that I can opt out of SMS and student
                portal communication at any time.</p>

            <div className="row acknowledge">
                {getAcknowledge("ACK-P6", "I acknowledge that I have read and understood the above policies.")}
            </div>

            <div className="row">
                {getSignature()}
            </div>
        </div>
    );
});