import { useState, useContext, useEffect } from 'react';
import { SchoolID } from '../../../../models/Enums';
import globalContext from '../../../../context/globalContext';
import ProgramService from '../../../../services/programs.service';
import { Days, ProgramShift, ScheduleDay } from '../../../../models/Program.model';
import ActionIcon from '../../../shared/action-icon/actionIcon';
import { XCircle as IconReject } from 'react-feather';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import './cardScheduleView.scss';
import { toast } from 'react-toastify';



const CardScheduleView = (props: any) => {
  const { setLoading } = useContext(globalContext);
  const [programShifts, setProgramShift] = useState([] as ProgramShift[]);
  const [scheduleDays, setScheduleDays] = useState([] as ScheduleDay[]);
  let editedScheduleDays = [] as any[];

  const loadData = async () => {
    setLoading(true);

    setScheduleDays([]);
    let [programShiftRes, scheduleDayRes] = await Promise.all([ProgramService.getShifts(), ProgramService.getScheduleDays()]);

    setProgramShift(programShiftRes);
    setScheduleDays(scheduleDayRes);
    setLoading(false);
  };

  const getShiftList = () => {
    return (
      <ul className="list-group">
        {programShifts
          ?.filter((x) => x.programId == props.selectedProgram.id)
          .map((shift: ProgramShift, index) => (
            <li key={shift.id} className="startdate list-group-item list-group-item-action border-bottom">
              <div className="date">
                <p>{shift.name}</p>
              </div>

              <ActionIcon label="Delete" tooltip="Delete" icon={<IconReject />} color="danger" extraClass="custom-delete-btn" onClick={() => handleDeleteShift(index)} />
            </li>
          ))}
      </ul>
    );
  };

  const showToast = (message: string) => {
    toast.dark(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
};

  const handleAddShift = async (event: any) => {
    event.preventDefault();
    let name = event.target.name.value;
    let programId = props.selectedProgram.id;

    if (programId != null && name != '') {
      setLoading(true);
      let payload = { name, programId } as ProgramShift;
      let response = await ProgramService.addShift(payload);
      setLoading(false);

      event.target.reset();
      var temp = [...programShifts];
      temp.push(response);
      setProgramShift(temp);

      showToast("✔️ Shift added successfully");
    }
  };

  const handleDeleteShift = async (index: number) => {
    setLoading(true);
    let shiftId = programShifts[index].id as string | number;
    let response = await ProgramService.removeShift(shiftId);
    setLoading(false);

    var temp = [...programShifts];
    temp.splice(index, 1);
    setProgramShift(temp);

    showToast("✔️ Shift deleted successfully");

  };

  const getSchedule = () => {
    if (props.selectedProgram?.id) {
      let schedule = [...scheduleDays].filter((day: ScheduleDay) => day.programId === props.selectedProgram.id);
  
      const allDays = Array.from({ length: 7 }, (_, index) => index); 
  
      allDays.forEach(day => {
        const existingDay = schedule.find(sDay => sDay.day === day);
        if (!existingDay) {
          schedule.push({ day, active: false, schoolId: 0, programId: 0, fromTime: '0', toTime: '0' } as ScheduleDay);
        }
      });
  
      schedule.sort((a, b) => a.day - b.day);

      return (
        <>
          {schedule.map((sDay: ScheduleDay, index) => (
            <div key={sDay.day} className="row row-xs pr-2">
              <div className="col-sm d-flex">
                <div className="form-check d-flex align-items-center pl-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`hasReminder-${sDay.day}`}
                  name={`hasReminder-${sDay.day}`}
                  defaultChecked={sDay.active}
                  onChange={(e) => {
                    scheduleChangeValue('active', e.target.checked, sDay.day);
                  }}
                />
                </div>
                <p>{Days[sDay.day]}: </p>
              </div>
              <div className="col-sm">
                <input
                  type="time"
                  className="form-control form-control-sm"
                  defaultValue={sDay?.fromTime}
                  onChange={(e) => {
                    scheduleChangeValue('fromTime', e.target.value, index);
                  }}
                />
              </div>
              <p className="to">to</p>
              <div className="col-sm">
                <input
                  type="time"
                  className="form-control form-control-sm"
                  defaultValue={sDay?.toTime}
                  onChange={(e) => {
                    scheduleChangeValue('toTime', e.target.value, index);
                  }}
                />
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  const validateSchedule = () => {
  
    scheduleDays.forEach((sDay: ScheduleDay, index) => {
      if (sDay.active) {
        const fromTime = editedScheduleDays[index]?.fromTime;
        const toTime = editedScheduleDays[index]?.toTime;
  
        if (!fromTime || !toTime) {
          showToast(`❌ Please enter both start and end times for ${Days[sDay.day]}.`);
        }
  
        if (fromTime && toTime && fromTime >= toTime) {
          showToast(`❌ End time must be greater than start time for ${Days[sDay.day]}.`);
        }
      }
    });
  
  };

  const scheduleChangeValue = (field: string, value: any, key: any) => {
    let existingScheduleDay = editedScheduleDays.find((item) => item.key === key);
    if (existingScheduleDay) {
      existingScheduleDay[field] = value;
    } else {
      let currentScheduleDay = { key: key } as any;
      currentScheduleDay[field] = value;
      editedScheduleDays.push(currentScheduleDay);
    }
  };

  const saveScheduleChanges = async () => {
    if (editedScheduleDays.length > 0) {
      setLoading(true);
  
      try {
        let resp = await ProgramService.updateScheduleDays(editedScheduleDays, props.selectedProgram.id as number, props.selectedSchool.id);
        setLoading(false);
  
        if (resp?.status === HttpStatusCode.Ok) {
          showToast("✔️ Schedule saved successfully");
  
          // Clear the editedScheduleDays state
          editedScheduleDays = [];
  
          // Use the updated data from the response to update scheduleDays
          setScheduleDays(prevScheduleDays => [...prevScheduleDays]);
        }
      } catch (error) {
        setLoading(false);
        showToast("❌ Error saving schedule changes. Please try again.");
      }
    }
  };

  useEffect(() => {
    if (!props.selectedSchool || !props.selectedProgram) return;
   loadData()
   setScheduleDays([]);
  }, [props.selectedSchool, props.selectedProgram]);

  return (
    <div className="card-schedule">
      {props.selectedSchool?.id == SchoolID.PA ? (
        <div className="card startdates">
          <div className="card-header startdate-header">
            <h5>Shifts</h5>
          </div>
          <div className="card-body overflow-auto">
            {!props.selectedProgram?.id && <p className="no-data">Select a program to see/modify it shifts.</p>}
            {getShiftList()}
          </div>
          <div className="card-footer">
            <h6>Register shift</h6>
            <div className="custom-form">
              <form onSubmit={handleAddShift}>
                <div className="input-wrapper">
                  <label htmlFor="date">Shift :</label>
                  <input type="text" className="form-control form-control-sm" name="name" />
                </div>
                <button type="submit" className="btn btn-sm btn-primary custom-form-btn">
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="card startdates">
          <div className="card-header startdate-header">
            <h5>Schedule</h5>
          </div>
          <div className="card-body schedule">
            {!props.selectedProgram?.id && <p className="no-data">Select a program to see/modify it schedule.</p>}
            {/* will have: id, school_id, program_id, bool, day, from, to */}
            {getSchedule()}
          </div>
          <div className="card-footer d-flex justify-content-center">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                saveScheduleChanges();
              }}
            >
              Save Schedule
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardScheduleView;