import { AgreementProps } from "../../types"
import classNames from '../sdgku.enrollment.module.css'
import { forwardRef, useEffect, useImperativeHandle } from "react"
import usePageSignature from "../../../../../hooks/pageSignature"
import { SDGKUFormsPage } from "../../../../../models/Forms.enum"
import '../../index.css'

const formatMoney = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const AgreementPage7 = forwardRef(({ data, program, visible }: AgreementProps, ref) => {
    const { saveForm, getInitials } = usePageSignature(data, false)

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(SDGKUFormsPage.Agreement7)
        },
    }))

    useEffect(() => {
        visible && window.scrollTo(0, 0);
        console.log(program, data);
    }, [visible])

    if (!visible) return null

    return (
        <div className="doc-content py-4">
             <p>
                <b>Graduation Requirements:</b>
                <ul>
                    <li>{program?.graduationRequirment1 || ''}</li>
                    <li>{program?.graduationRequirment2 || ''}</li>
                    {program?.graduationRequirment3 && <li>{program.graduationRequirment3}</li> }
                </ul>
             </p>
            <p>
              Participation (physical or virtual) in graduation activities is contingent upon completion of all graduation requirements. In exceptional cases where minimal requirements have not been completed, arrangements must be made in advance with the Chief Academic Officer (CAO). 
            </p>


            <p>
                <b>FEES AND CHARGES: </b> The student is responsible for the following fees and charges (please refer to the SDGKU General Catalog for the specific costs for each item)
            </p>
            <div className="container-fluid">
                {
                    program?.fees.map((fee, index) => (
                        <div className="row mb-2 align-items-end" key={index}>
                            <div className={`${fee.feeCondition ? 'col-4' : 'col-9'}`}>
                                <b>{fee.feeName}</b>
                            </div>
                            {
                                fee.feeCondition &&
                                <div className="col-5 align-self-center">
                                    {fee.feeCondition}
                                </div>
                            }
                            {
                                fee?.feeAmount && fee?.feeAmount != -1 &&
                                <div className={`${classNames.amount} col-3 text-right`}>
                                    {`${formatMoney(fee.feeAmount)} USD`}
                                </div>
                            }

                        </div>
                    ))
                }
                <div className="row mb-2">
                    <div className="col-10">
                        <p>Each qualifying institution shall collect an assessment of zero dollars ($0.00) per one thousand dollars ($1,000) of institutional charges, rounded to the nearest thousand dollars, from each student in an educational program who is a California resident or is enrolled in a residency program. For institutional charges of one thousand dollars ($1,000) or less, the assessment is zero
                            dollars ($0).
                        </p>
                    </div>
                </div>
            </div>
            <p>
                <b>Payment Method: </b> San Diego Global Knowledge University (SDGKU), furthermore known in the document as SDGKU accepts the following payment methods, cash, check and/or credit card, and/or Federal financial aid.
            </p>
            <p>
                <b>Note: </b>Upon enrollment the student is obligated to pay the registration fee, the tuition fee, and the textbook/online materials fee for the first course in a degree program. For non-degree programs, the registration fee, the total program tuition fee, and the textbook/online materials must be paid upon enrollment. The S.T.R.F. assessment fee must be included at every payment made by the student). Because San Diego Global Knowledge University offers distance education programs where the instruction is not offered in real time, in such a case it shall transmit the first lesson and any materials to the student within seven days after San Diego Global Knowledge University accepts the student for admission.
            </p>

            <div className="position-relative">
                {getInitials('page7')}
            </div>
        </div>
    )
})