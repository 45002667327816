import React, { useContext, useEffect, useState } from "react";
import "./disclosureIpad.scss";
import { SignedPageData } from "../../../../viewControllers/signedPage";
import DisclosureCompleted from "./disclosureCompleted";
import globalContext from "../../../../context/globalContext";
import { AlertType } from "../../../../models/AlertType.enum";
import signedFormService from "../../../../services/signedForm.service";
import { SignedFormKey } from "../../../../models/Forms.enum";
import {
  IpadSignedForm,
  SignedForm,
  SignedFormStudent,
} from "../../../../models/Forms.model";
import { COBAProgram, COBAProgramFromName } from "../../../../models/Enums";
import { HttpStatusCode } from "../../../../models/common/HttpStatusCode.enum";
import Signature from "../../../../components/shared/signature/signature";
import TextField from "../../../../components/shared/Input/textField";
import { Student } from "../../../../models/Student.model";
import { useForm } from "react-hook-form";
import SelectField from "../../../../components/shared/Input/selectField";
import usePageSignature from "../../../../hooks/pageSignature";
import moment from "moment";

interface Props {
  location: any;
}

interface extraData {
  option1: boolean;
  option2: boolean;
  cosmetology: boolean;
  barber: boolean;
  esthetician: boolean;
  checkReviewed: boolean;
}

interface IpadDisclosureForm {
  student: Student;
  program: COBAProgram;
  extraData: extraData;
}

const IpadDisclosure = ({ location }: Props) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm<IpadDisclosureForm>();
  const { setLoading, showAlert } = useContext(globalContext);
  const [tokenError, setTokenError] = useState(false);
  const [viewController, setViewController] = useState<SignedPageData>(null!);

  const [completed, setCompleted] = useState(false);
  const [student, setStudent] = useState<SignedFormStudent>(null!);
  const [program, setProgram] = useState<COBAProgram>(null!);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [checkreviewed, setCheckreviewed] = useState(false);
  const [handleOption, setHandleOption] = useState(false);

  const [extraData, setExtraData] = useState<extraData>(null!);
  const { saveForm, getSignature } = usePageSignature(viewController);

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (program) {
      programName();
    }
  }, [program]);

  const loadInitialData = async () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const studentId = params.get("id");

    if (token && studentId) {
      await loadForm(+studentId, token);
    } else {
      showAlert("Invalid token", AlertType.Error);
      setTokenError(true);
    }
  };

  const loadForm = async (studentId: number, token: string) => {
    setLoading(true);
    try {
      const resp = await signedFormService.getFormData(
        studentId,
        SignedFormKey.IPAD_DISCLOSURE,
        token
      );
      if (resp?.status === HttpStatusCode.Ok) {
        const program = COBAProgramFromName(resp.data.programName);
        setProgram(program);
        initializeControllers(resp?.data, program, token);
      } else if (resp?.status === HttpStatusCode.Forbidden) {
        showAlert("Invalid token", AlertType.Error);
        setTokenError(true);
      }
    } catch {
      showAlert("Unexpected error", AlertType.Error);
      setTokenError(true);
    } finally {
      setLoading(false);
    }
  };

  const initializeControllers = (
    formData: SignedForm,
    program: COBAProgram,
    token: string
  ) => {
    if (!formData) return;

    const timezone = moment.tz.guess();
    setValue("student.firstName", formData.student.firstName);
    setValue("student.middleName", formData.student.middleName!);
    setValue("student.lastName", formData.student.lastName);
    setValue("student.ssn", formData.student.ssn!);
    setValue(
      "student.birthDate",
      moment.tz(formData.student.birthDate!, timezone).format("MM/DD/YYYY")
    );
    setValue("student.email", formData.student.email);
    setValue("student.phone", formData.student.phone);
    setStudent(formData.student);
    setViewController(
      new SignedPageData(
        SignedFormKey.IPAD_DISCLOSURE,
        program,
        formData,
        token,
        setEnableSubmit
      )
    );
  };

  const submitForm = async () => {
    if (checkreviewed === true) {
      setExtraData((prevState) => ({
        ...prevState,
        checkReviewed: true,
      }));
    }
    let data: extraData = null!;
    if (extraData) {
      data = { ...extraData };
    }

    if (await saveForm(SignedFormKey.IPAD_DISCLOSURE, true, data)) {
      setCompleted(true);
    } else {
      showAlert(
        "Error while saving. Please verify all fields and try again",
        AlertType.Error
      );
    }
  };

  const enableCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckreviewed(event.target.checked);
  };

  const programName = async () => {
    console.log(program);
    switch (program) {
      case 1:
        selectedProgram(false, true, false);
        break;
      case 2:
        selectedProgram(true, false, false);
        break;

      case 3:
        selectedProgram(false, false, true);
        break;
      case 4:
        selectedProgram(false, true, false);
        break;
      default:
        console.error("Invalid option selected");
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHandleOption(true);
    const id = event.target.id;
    if (id === "option1_check") {
      setExtraData((prevState) => ({
        ...prevState,
        option1: true,
        option2: false,
      }));
    } else if (id === "option2_check") {
      setExtraData((prevState) => ({
        ...prevState,
        option1: false,
        option2: true,
      }));
    }
  };

  const selectedProgram = (
    cosmetology: boolean,
    barber: boolean,
    esthetician: boolean
  ) => {
    setExtraData((prevState) => ({
      ...prevState,
      cosmetology,
      barber,
      esthetician,
    }));
  };

  const isFormValid =
    enableSubmit && extraData !== null && checkreviewed && handleOption;

  return (
    <div className="mx-auto p-3 doc-disclosure">
      {completed ? (
        <div className="preapp-form container cobaDisclosure-form">
          <h2>
            <img src="/static/coba/logo-coba.png" alt="coba logo"></img>
          </h2>

          <div className="doc-area">
            <div className="doc-content thank-you">
              <div>
                <h3>Thank you for completed the Disclosure Ipad Form.</h3>
                <p>You can now close this page.</p>
              </div>
            </div>
          </div>
        </div>
      ) : tokenError ? (
        <div className="preapp-form container cobaDisclosure-form">
          <h2>
            <img src="/static/coba/logo-coba.png" alt="coba logo"></img>
          </h2>

          <div className="doc-area">
            <h5 className="error-token">
              Your Token seems invalid or it expired, please get in contact with
              the university personnel to request a new one if you haven't
              completed the form.
            </h5>
          </div>
        </div>
      ) : (
        <div className="disclosure-ipad">
          <div className="preapp-form container ipadDisclosure-form">
            <div className="row">
              <div className="col-9">
                <img src="/static/coba/logo-coba.png" alt="coba logo" />
              </div>
              <div className="col-3">
                <p className="text-center">
                  Coba Academy <br />
                  663 N. Euclid St., Anaheim, CA 92801 | 714-533-1400 |
                  www.coba.edu <br />
                </p>
              </div>
            </div>
          </div>

          <div className="title">
            <h3>Apple iPad Disclosure Form</h3>
          </div>

          <div className="container-fluid-mb-3 form-group">
            <div className="row">
              <div className="col-4">
                <TextField
                  disabled
                  errors={errors}
                  label="First Name"
                  field="student.firstName"
                  type="text"
                  register={register}
                />
              </div>
              <div className="col-4">
                <TextField
                  disabled
                  errors={errors}
                  label="Middle Name"
                  field="student.middleName"
                  type="text"
                  register={register}
                />
              </div>
              <div className="col-4">
                <TextField
                  disabled
                  errors={errors}
                  label="Last Name"
                  field="student.lastName"
                  type="text"
                  register={register}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <TextField
                  disabled
                  errors={errors}
                  label="SSN"
                  field="student.ssn"
                  type="text"
                  register={register}
                />
              </div>
              <div className="col-4">
                <TextField
                  disabled
                  errors={errors}
                  label="Birthday"
                  field="student.birthDate"
                  type="text"
                  register={register}
                />
              </div>
              <div className="col-4">
                <SelectField
                  field="gender"
                  label="Gender"
                  errors={errors}
                  control={control}
                  disabled
                  initialValue={Number(student?.gender)}
                  options={[
                    { label: "Male", value: 1 },
                    { label: "Female", value: 2 },
                    { label: "Prefer not to say", value: 3 },
                  ]}
                />
              </div>

              <div className="col-4">
                <SelectField
                  field="program"
                  label="Program"
                  errors={errors}
                  control={control}
                  disabled
                  initialValue={Number(program)}
                  options={[
                    { label: "Barber", value: 1 },
                    { label: "Cosmetology", value: 2 },
                    { label: "Esthetician", value: 3 },
                    { label: "Barber Hybrid", value: 4 },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="option-meesage">
            <h5>Please select one of the following options from below:</h5>
          </div>

          <div className="option-container border-bottom">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="option1_check"
                onChange={handleOptionChange}
              />
              <div className="labels">
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Optional 1 – Add iPad cost to my Total Investment
                </label>
                <label
                  className="form-check-label price"
                  htmlFor="flexRadioDefault1"
                >
                  Price $350.00
                </label>
              </div>
            </div>

            <div className="message">
              <label htmlFor="">
                I chose the iPad cost towards my Total Investment for a new iPad
                wi-fi 32GB at the following price, added to the kit cost.
              </label>
            </div>

            <ul className="custom-list">
              <li>Barbering Program Total Kit Cost with iPad $2,350.00</li>
              <li>Cosmetology Program Total Kit Cost with iPad $2,490.00</li>
              <li>Esthetics Program Total Kit Cost with iPad $2,580.00</li>
            </ul>
          </div>


          <div className="option-container border-bottom">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="option2_check"
                onChange={handleOptionChange}
              />
              <div className="labels">
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Optional 2 – Purchase an iPad on my <u>OWN</u> outside Coba
                  Academy
                </label>
              </div>
            </div>

            <div className="message">
              <label htmlFor="">
                I choose{" "}
                <u>
                  <b>NOT</b>
                </u>{" "}
                to purchase the iPad wi-fi 32GB through Coba Academy
              </label>
            </div>

            <ul className="custom-list">
              <li>
                Opting out does{" "}
                <u>
                  <b>NOT</b>
                </u>{" "}
                <b>require</b> Coba Academy to provide you with an alternative
                delivery method. You will receive a textbook, workbook, and
                MindTap Access software. In addition, I understand it will be{" "}
                <u>
                  <b>my responsibility to</b>
                </u>{" "}
                to obtain an iPad wi-fi 32GB or a compatible device to complete
                all MindTap assignments.
              </li>
            </ul>
          </div>

          <div className="foot-info">
            <p className="mt-3 ">
              To view more information on the specs of the iPad, please visit:{" "}
              <a href="https://support.apple.com/en-us/111898" target="_blank">
              https://support.apple.com/en-us/111898
              </a>
            </p>
          </div>

          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              checked={checkreviewed}
              onChange={enableCheckbox}
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              <b>
                I have reviewed, fully understood, and accepted my selected
                option for my program.
              </b>
            </label>
          </div>

          <div className="pad">{getSignature()}</div>

          <div className="d-flex align-items-center justify-content-center">
            <button
              disabled={!isFormValid}
              className="btn btn-primary"
              type="submit"
              onClick={submitForm}
            >
              Submit Signed Form
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IpadDisclosure;
