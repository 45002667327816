import * as React from "react";
import ReactTooltip from "react-tooltip";
import Modal from "../../../components/modal/modal";
import ApplicationRequestService from "../../../services/applicationRequest.service";

import { ApplicationForm, GetApplicationPaymentMethodsString } from "../../../models/ApplicationForm.model";
import { BackEndUrl, DebugBackEndUrl, isLocalHost } from "../../../services/shared.service";
import { ToastContainer, toast } from "react-toastify";
import { ApplicationStatus, ApplicationStatusName, SchoolID } from "../../../models/Enums";

import { Info as IconLink, Download as IconDownload, CheckCircle as IconApprove, XCircle as IconReject } from "react-feather";
import "./pendingApplicationRequest.scss";
import "react-toastify/dist/ReactToastify.css";
import globalContext from "../../../context/globalContext";
import Select from "react-select";
import { School } from "../../../models/School.model";
import { SelectOption } from "../../../models/common/Base.model";
import schoolService from "../../../services/school.service";
import dateService from "../../../services/date.service";
import SearchBox from "../../../components/shared/searchBox/searchBox";
import DateRangePicker from "../../../components/shared/dateRangePicker/dateRangePicker";
import ActionIcon from "../../../components/shared/action-icon/actionIcon";
import CopyStepLink from "../../../components/shared/copy-step-link/copyStepLink";
import Loader from "react-loader-spinner";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import TitleBar from "../../../components/shared/titleBar/titleBar";
import { CacheConstants } from '../../../services/constants.service';
import { Link } from 'react-router-dom';

class PendingApplicationRequest extends React.Component<any, any> {
  static contextType = globalContext;

  modalReject = {
    main: 'Reject Request',
    mainClass: 'btn-danger',
    title: 'Do you want to reject the request?',
  };

  modalReactivate = {
    main: 'Reactivate Request',
    mainClass: 'btn-primary',
    title: 'Do you want to reactivate the request?',
  };

  modalApprove = {
    main: 'Approve Request',
    mainClass: 'btn-primary',
    title: 'Do you want to approve this request?',
  };

  statusFilterOptions = [
    { value: 0, label: 'Active' },
    { value: 1, label: 'Approved' },
    { value: 2, label: 'Rejected' },
    { value: "ALL", label: 'All' },
  ];

  state = {
    pendingApplications: [] as ApplicationForm[],
    modalInfo: this.modalReject,
    modalVisible: false,
    action: ApplicationStatus.REJECTED,
    actionDescription: '',
    formId: 0,
    filter: 0,
    search: '',
    schools: [],
    fromDate: '',
    toDate: '',
    selectedSchool: null as any,
    selectedStatus: this.statusFilterOptions[0],
    selectedApplication: {} as ApplicationForm,
    copyLinkVisible: false,
    tableLoading: false,
  };

  multipleSchools = () => {
    return this.context.user.isReviewer || this.context.user.isAdmin;
  };

  hasSchool = (school: SchoolID) => {
    return this.context.user.isAdmin || this.context.user.schools.some((sId: any) => sId === school);
  };

  showStep = () => {
    return +this.state.filter === 0 && this.hasSchool(SchoolID.SDGKU);
  };

  getSchoolName = (schoolId: any) => {
    switch (schoolId) {
      case 1:
        return 'SCCBB';
      case 2:
        return 'PA';
      case 3:
        return 'SDGKU';
      case 4:
        return 'UW LA';
      case 5:
          return 'COBA';
    }

    return '';
  };

  copyLink = (record: ApplicationForm) => {
    this.setState({
      ...this.state,
      selectedApplication: record,
      copyLinkVisible: true,
    });
  };

  getStepDisplay = (record: ApplicationForm) => {
    if (record.student?.school !== SchoolID.SDGKU || record.status !== ApplicationStatus.SUBMITTED) {
      return <label className={'status-' + record.status}>{ApplicationStatusName(record.status)}</label>;
    }
    if (record.student?.school == SchoolID.SDGKU) {
      return (
        <>
          {record.step}/2
          <a
            className="copy-link"
            data-tip="Copy Link to continue application"
            onClick={() => {
              this.copyLink(record);
            }}
          >
            <IconLink />
          </a>
        </>
      );
    }

    return (
      <>
        {record.step}/2
        <a
          className="copy-link"
          data-tip="Copy Link to continue application"
          onClick={() => {
            this.copyLink(record);
          }}
        >
          <IconLink />
        </a>
      </>
    );
  };

  getControlsColumn = (recordId: number, status: ApplicationStatus) => {
    if (this.state.filter === ApplicationStatus.REJECTED) {
      return (
        <>
          <ActionIcon
            label="Download"
            tooltip="Download application"
            icon={<IconDownload />}
            color="primary"
            onClick={() => this.handleDownloadForm(recordId)}
          />
          {/* <ActionIcon
            label="Reactivate"
            tooltip="Reactivate Application"
            icon={<IconReactivate />}
            color="primary"
            onClick={() => this.handleReactivateRequest(recordId)}
          /> */}
        </>
      );
    }

    return (
      <>
        <ActionIcon
          label="Download"
          tooltip="Download application"
          icon={<IconDownload />}
          color="primary"
          onClick={() => this.handleDownloadForm(recordId)}
        />
        {status === ApplicationStatus.SUBMITTED ? (
          <>
            <ActionIcon
              label="Accept"
              tooltip="Approve Application"
              icon={<IconApprove />}
              color="success"
              onClick={() => this.handleAcceptRequest(recordId)}
            />
            <ActionIcon
              label="Reject"
              tooltip="Reject Application"
              icon={<IconReject />}
              color="danger"
              onClick={() => this.handleRejectRequest(recordId)}
            />
          </>
        ) : null}
      </>
    );
  };

  isEmployed = (record: ApplicationForm) => {
    let employed = record.currentlyEmployed;    
    return employed ? 'Yes' : 'No';
  };

  getTable = (multiSchool: any) => {
    return (
      <>
        {this.state.tableLoading ? (
          <div className="inline-waiting text-center">
            <Loader type="Circles" color="#001737" height="100" width="100" />
            <h6 className="mg-t-10">Loading data...</h6>
          </div>
        ) : null}

        {/* TODO: Should be refactor to use the Table component with navigation */}
        <table className="table table-bordered table-hover table-striped ">
          <thead className="thead-secondary">
            <tr>
              {/* <th>Id</th> */}
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              {multiSchool && <th>School</th>}
              <th className="text-center">Program</th>
              <th className="text-center">Employed</th>
              <th>Plan Finance</th>
              <th>Created at</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {!this.state.tableLoading
              ? this.state.pendingApplications.filter(this.dataFilter).map((record, index) => {
                  return (
                    <tr key={index}>
                      {/* <td>{record.id}</td> */}
                      <td>
                        <Link to={'/student/overview/' + record.student?.id}>
                          {record.student?.firstName} {record.student?.lastName}
                        </Link>
                      </td>
                      <td>{record.student?.email}</td>
                      <td className="td-150">{record.student?.phone}</td>
                      {multiSchool && <td>{this.getSchoolName(record.student?.school)}</td>}
                      <td className="text">{record.program}</td>
                      <td className="text-center">{this.isEmployed(record)}</td>
                      <td className="td-100">{this.getFinancialPayment(record)}</td>
                      <td className="td-100">{this.getFormattedDate(record)}</td>

                      <td className="td-status">{this.getStepDisplay(record)}</td>

                      <td className="td-options">{this.getControlsColumn(record.id, record.status)}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!this.state.tableLoading && this.state.pendingApplications.length < 1 ? (
          <h4 className="centered">No applications found. Please adjust the filter.</h4>
        ) : null}
      </>
    );
  };

  render() {
    const multiSchool = this.multipleSchools();
    return (
      <>
        <Modal
          visible={this.state.modalVisible}
          cancelButtonText="Cancel"
          mainButtonText={this.state.modalInfo.main}
          mainButtonClass={this.state.modalInfo.mainClass}
          title={this.state.modalInfo.title}
          onCancel={this.handleModalClose}
          onClose={this.handleModalClose}
          onAccept={this.handleModalAccept}
        ></Modal>

        <CopyStepLink
          applicationForm={this.state.selectedApplication}
          visible={this.state.copyLinkVisible}
          onClose={() => this.setState({ ...this.state, copyLinkVisible: false })}
        />

        <TitleBar header="Application Requests" subheader="Received applications"></TitleBar>

        <div className="page pending-application-request-page">
          <ToastContainer />
          <ReactTooltip place="bottom" type="dark" effect="float" delayShow={500} />

          <div className="row row-filters mg-b-10">
            <SearchBox
              className="col-md-4 col-lg-3 mx-sm-0 mx-lg-2"
              label="Search Applicants"
              placeholder="Search Applicants"
              onChange={(search: string) => this.setState({ search })}
            />

            <DateRangePicker
              fromValue={this.state.fromDate}
              toValue={this.state.toDate}
              sendRange={(from: string, to: string) => this.handleMonthChange(from, to)}
            ></DateRangePicker>

            {multiSchool && (
              <div className="col-md-4 col-lg-3 col-xl-2 mx-sm-0 mx-lg-2">
                <label>School</label>
                <Select
                  placeholder="Any School"
                  options={this.getSchoolOptions()}
                  value={this.state.selectedSchool}
                  onChange={(selectedSchool: SelectOption) => this.setState({ selectedSchool })}
                />
              </div>
            )}

            <div className="col-md-3 col-lg-3 col-xl-2 mx-sm-0 mx-lg-2">
              <label>Status</label>
              <Select
                placeholder="Any Status"
                options={this.statusFilterOptions}
                value={this.state.selectedStatus}
                onChange={(selectedStatus: any) => {
                  this.setState({ selectedStatus });
                  this.handleFilterChange(selectedStatus.value);
                }}
              />
            </div>
          </div>

          {this.getTable(multiSchool)}
        </div>
      </>
    );
  }

  handleMonthChange = (fromDate: string, toDate: string) => {
    this.setState({ fromDate, toDate });
    console.log("date changed");
    this.getApplications(fromDate, toDate, this.state.filter);
  };

  handleFilterChange = (status: any) => {
    this.setState({ filter: status });

    this.getApplications(this.state.fromDate, this.state.toDate, status);
  };

  setInitialDates = () => {
    let today = new Date();
    let { fromDate, toDate } = dateService.getFirstLastDayOfMonthStr(today);
    this.setState({ fromDate: fromDate, toDate: toDate });
  };

  getSchoolOptions = () => {
    let options = this.state.schools?.map((school: School) => ({
      value: school.id,
      label: school.shortName,
    }));
    return [{ value: undefined, label: '- Any Schools -' }, ...options];
  };

  dataFilter = (row: ApplicationForm) => {
    let search = this.state.search.toLocaleLowerCase();
    return (
      (!search ||
        row.id.toString() === search ||
        row.student?.firstName?.toLowerCase().includes(search) ||
        row.student?.lastName?.toLowerCase().includes(search) ||
        row.student?.email?.toLowerCase().includes(search) ||
        row.program?.toLowerCase().includes(search)) &&
      (!this.state.selectedSchool || !this.state.selectedSchool.value || row.student?.school === this.state.selectedSchool.value)
    );
  };

  getFormattedDate(record: ApplicationForm) {
    return new Date(record.createdAt).toLocaleDateString();
  }

  getFinancialPayment(record: ApplicationForm) {
    return GetApplicationPaymentMethodsString(record, " | ");
  }


  handleInputChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckChange = (e: any) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleApproveRequest = (formId: number) => {
    this.setState({ modalVisible: true });
  };

  handleRejectRequest = (formId: number) => {
    this.setState({
      modalVisible: true,
      formId: formId,
      action: ApplicationStatus.REJECTED,
      actionDescription: 'Application Rejected',
      modalInfo: this.modalReject,
    });
  };

  handleReactivateRequest = (formId: number) => {
    this.setState({
      modalVisible: true,
      formId: formId,
      action: ApplicationStatus.SUBMITTED,
      actionDescription: 'Application Reactivated',
      modalInfo: this.modalReactivate,
    });
  };

  handleAcceptRequest = (formId: number) => {
    this.setState({
      modalVisible: true,
      formId: formId,
      action: ApplicationStatus.APPROVED,
      actionDescription: 'Application Approved',
      modalInfo: this.modalApprove,
    });
  };

  handleDownloadForm = (formId: number) => {
    window.open((isLocalHost() ? DebugBackEndUrl : BackEndUrl) + '/api/reports/application_requests/' + formId, 'newwindow', 'width=820px,height=950px');
    return false;
  };

  componentWillMount() {
    this.setInitialDates();
  }

  componentDidMount() {
    //this.getApplications(this.state.fromDate, this.state.toDate, this.state.filter);
  }

  async getApplications(fromDate: any, toDate: any, status: any) {
    this.setState({ tableLoading: true });
    let schoolPromise = this.context.getWithCache(CacheConstants.SCHOOLS, schoolService.getSchools);
    let appReqPromise = ApplicationRequestService.getFilteredApplicationRequests(fromDate, toDate, status);

    let [schools, pendingApplications] = await Promise.all([schoolPromise, appReqPromise]);
    this.setState({ schools, pendingApplications });
    this.setState({ tableLoading: false });
  }

  handleModalClose = () => {
    this.setState({ modalVisible: false });
  };

  updateRecordStatus = (formId: number, status: ApplicationStatus) => {
    let applications = this.state.pendingApplications;
    let index = applications.findIndex((app: ApplicationForm) => app.id === formId);
    applications.splice(index, 1);
    this.setState({ pendingApplications: applications });
  };

  handleModalAccept = async () => {
    this.setState({ modalVisible: false });
    let alertText = '✔️ ' + this.state.actionDescription;
    let res = await ApplicationRequestService.changeApplicationStatus(this.state.formId, this.state.action);

    if (res?.status === HttpStatusCode.Ok) {
      toast.dark(alertText, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      this.updateRecordStatus(this.state.formId, this.state.action);
    } else {
      toast.error('❌ Error approving request', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      console.error(res);
    }

    // update data in state
    let data = this.state.pendingApplications;
    let records = data.filter((record) => record.id === this.state.formId);
    if (records.length > 0) records[0].status = this.state.action;
    this.setState({ pendingApplications: data });
  };
}

export default PendingApplicationRequest;
