import { useEffect, useState, useRef, useContext } from 'react';
import Note from './note';
import Email from './email';
import { toast } from 'react-toastify';
import studentNoteService from '../../../services/studentNote.service';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { RefreshCw as IRefresh, Mail as IMail, File as IFile } from 'react-feather';

import './studentCommunication.scss';
import emailService from '../../../services/email.service';
import EmailSendingModal from '../emailSendigModal/emailSendingModal';
import { Student } from '../../../models/Student.model';
import globalContext from '../../../context/globalContext';
import AddNoteModal from './addNoteModal';
import Select from 'react-select';
import { SelectOption } from '../../../models/common/Base.model';

export interface StudentCommunicationProps {
  visible: boolean;
  studentId: number;
  student: Student;
  updateData: boolean;
  setUpdateData: (value: boolean) => void;
  onClose?: () => void;
}

export enum NoteType {
  Note = 'Note',
  Email = 'Email',
  Sms = 'Sms',
  Call = 'Call',
  Meeting = 'Meeting',
  All = 'All',
}

const StudentCommunication = (props: StudentCommunicationProps) => {
  const [data, setData] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState(NoteType.All);
  const [sendEmailVisible, setSendEmailVisible] = useState(false);
  const [addNoteVisible, setAddNoteVisible] = useState(false);

  const { user } = useContext(globalContext);

  const saveNote = async (text: string) => {
    let tempNote = { text: text, studentId: props.studentId, creatorId: 1 };

    let response = await studentNoteService.saveStudentNote(tempNote);

    if (response?.status === HttpStatusCode.Created || response?.status === HttpStatusCode.Created) {
      // add the type
      const newNote = { ...response.data, type: NoteType.Note };
      setData([newNote, ...data]);
      setAddNoteVisible(false);
    } else {
      toast.error('❌ Save Note failed', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const loadData = async () => {
    if (!props.studentId) return;

    setLoading(true);
    await emailService.markAllAsRead(props.studentId);
    const [notesResponse, emailsResponse] = await Promise.all([
      studentNoteService.getStudentNotes(props.studentId),
      emailService.getEmails(props.studentId),
    ]);

    if (notesResponse?.status && emailsResponse?.status === HttpStatusCode.Ok) {
      // set type and combine data into a single array
      const notes = notesResponse.data.map((note: any) => ({
        ...note,
        type: NoteType.Note,
      }));
      const emails = emailsResponse.data.map((email: any) => ({
        ...email,
        type: NoteType.Email,
      }));

      const sortedData = [...notes, ...emails].sort((a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)));
      setData(sortedData);
    }

    setLoading(false);
  };

  const getDisplayableRecords = () => {
    const filtered = data.filter((item: any) => filterType === NoteType.All || filterType === item.type);
    return filtered.map((item: any, index: any) => {
      if (item.type === NoteType.Note) {
        return <Note data={item} key={index} />;
      } else {
        return <Email data={item} key={index} />;
      }
    });
  };

  const getFilterOptions = () => {
    return [
      {
        label: 'All Feedback',
        value: NoteType.All,
      },
      {
        label: 'Notes',
        value: NoteType.Note,
      },
      {
        label: 'Emails',
        value: NoteType.Email,
      },
    ];
  };

  const handleOnEmailSent = () => {
    loadData();
  }

  useEffect(() => {
    loadData();
  }, [props?.studentId]);

  useEffect(() => {
    if (props.updateData) {
      loadData();
      props.setUpdateData(false);
    }
  }, [props.updateData]);

  return (
    <div className="student-notes">
      {sendEmailVisible ? <EmailSendingModal visible={true} student={props.student} user={user} onSent={handleOnEmailSent} close={() => setSendEmailVisible(false)} /> : null}
      {addNoteVisible ? <AddNoteModal visible={true} saveNote={saveNote} close={() => setAddNoteVisible(false)} /> : null}

      <div className="row">
        <div className="col-md-6 row-actions">
          <Select
            className="filter-select"
            placeholder="Filter by type"
            options={getFilterOptions()}
            // value={filterType}
            onChange={(selectedOption: SelectOption | null) => {
              console.log(selectedOption);
              setFilterType(selectedOption?.value as NoteType);
            }}
          />
        </div>

        <div className="col-md-6 row-actions">
          <button className="btn btn-sm btn-primary" onClick={() => setSendEmailVisible(true)}>
            <IMail /> Send New Email
          </button>

          <button className="btn btn-sm btn-warning" onClick={() => setAddNoteVisible(true)}>
            <IFile /> Add New Note
          </button>
        </div>
      </div>

      <div className="list-container visible-scrollbar">
        <div className="notes">{loading ? <div className="text-center loader-dots">Loading Data...</div> : getDisplayableRecords()}</div>
      </div>
    </div>
    //   }
    //   bodySubtitle="Save and share info related to student"
    //   size="lg"
    //   mainClass="student-notes-modal"
    //   visible={props.visible}
    //   flyout={true}
    //   mainButtonText={switchButton ? "" : "Add note"}
    //   cancelButtonClass="btn-dark"
    //   onCancel={props.onClose}
    //   onClose={props.onClose}
    //   onAccept={saveNote}
    // />
  );
};

export default StudentCommunication;
