import { useForm } from "react-hook-form";
import TextField from "../../../../../components/shared/Input/textField";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { CobaFinancialAidPageProps } from "../cobaFinancialAidPageProps";
import usePageSignature from "../../../../../hooks/pageSignature";
import { COBAFormsPage } from "../../../../../models/Forms.enum";

interface FinancialAidPage4StudentForm {
    name: string;
    ssn4: string;
    email: string;
    phone: string;
}

interface FinancialPage4Form {
    student: FinancialAidPage4StudentForm;
}

export const FinancialAidPage4 = forwardRef(({ data, visible }: CobaFinancialAidPageProps, ref) => {
    const { register, formState: { errors }, setValue } = useForm<FinancialPage4Form>();
    const { saveForm, getSignature, getAcknowledge } = usePageSignature(data, true);

    useImperativeHandle(ref, () => ({
        async saveData() {
            return await saveForm(COBAFormsPage.FinancialAid4)
        },
    }));

    useEffect(() => {
        if (data && data.formData) {
            setValue("student.name", data.formData.student.firstName + " " + data.formData.student.lastName)
            setValue("student.email", data.formData.student.email)
            setValue("student.phone", data.formData.student.phone)
            setValue("student.ssn4", data.formData.student.ssn?.slice(-4) || "")
        }
    }, [data])

    useEffect(() => {
        visible && window.scrollTo(0, 0)
    }, [visible])

    if (!visible) return null

    return (
        <div className="financialAid-page4">
            <div className="title">
                <h3>CONSUMER INFORMATION AWARENESS DISCLOSURE</h3>
            </div>
            <h6 className="subtitle">Coba Academy (Coba) makes its consumer information and offered educational programs available to all prospective
                ans enrolled students at the following website https://coba.edu/:</h6>
            <h4 className="coba-link">https://coba.edu/general-catalog/</h4>
            <h5>Student's Certification</h5>

            <p>Upon meeting with the Office of Financial Aid at Coba, I have been advised about the Academy's consumer
                information ans catalog that are available at the websites listed above.</p>

            <div className="form">
                <div className="row">
                    <div className="col-5">
                        <TextField disabled errors={errors} label="Student Name" field='student.name' type='text' register={register} />
                    </div>
                    <div className="col-5">
                        <TextField disabled errors={errors} label="Last 4-digits of SSN" field='student.ssn4' type='text' register={register} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <TextField disabled errors={errors} label="Email" field='student.email' type='text' register={register} />
                    </div>
                    <div className="col-5">
                        <TextField disabled errors={errors} label="Phone" field='student.phone' type='text' register={register} />
                    </div>
                </div>
            </div>
            <div className="row acknowledge">
                {getAcknowledge("ACK-P4", "I acknowledge that I have been advised about the Academy's consumer information and catalog.")}
            </div>
            <div className="row">
                {getSignature()}
            </div>
        </div>
    );
});