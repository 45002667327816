import * as React from "react";
import "./modal.scss";
import { ArrowLeftCircle, ArrowRight } from "react-feather";

export interface ModalProps {
  visible: boolean;
  title?: any;
  bodyTitle?: any; // use this only if flyout is true
  bodySubtitle?: any;
  body?: any;
  flyout?: boolean;

  cancelButtonText?: string;
  cancelButtonClass?: string;

  actionButtonText?: string;

  mainButtonText?: any;
  mainButtonClass?: string;
  mainButtonDisabled?: boolean;
  mainBUttonIcon?: any;

  secondaryButtonText?: any;
  secondaryButtonClass?: string;
  secondaryButtonDisabled?: boolean;
  secondaryButtonIcon?: any;

  hideFooter?: boolean;
  size?: string;
  mainClass?: string;
  disableClose?: boolean;
  disableAction?: boolean;

  onAccept?: () => void;
  onSecondary?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  onAction?: () => void;
}

class Modal extends React.Component<ModalProps, any> {
  state = {};
  render() {
    if (!this.props.visible) {
      return null;
    }

    const getBaseClass = () => {
      let classN = "modal-dialog ";
      if (this.props.flyout) {
        classN += "fadeInRight animated ml-auto ";
      }

      if (this.props.size) {
        classN += " modal-" + this.props.size;
      }
      return classN;
    };

    const handleClose = () => {
      this.props.onClose && this.props.onClose();
    };
    const handleAction = () => {
      this.props.onAction && this.props.onAction();
    };

    // traditional modal header
    const header = () => {
      return (
        <div className="modal-header">
          <h5 className="modal-title">{this.props.title}</h5>
          {this.props.disableAction ? (
            <button
              type="button"
              className="btn-action btn btn-sm btn-outline-primary"
              onClick={handleAction}
            >
              {this.props.actionButtonText}
            </button>
          ) : null}
          {!this.props.disableClose ? (
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          ) : null}
        </div>
      );
    };

    // big header inside the modal body
    const bodyHeader = () => {
      return (
        <div className="modal-body-title">
          <div
            className={
              !this.props.bodySubtitle
                ? "title-row  title-row_no-sub"
                : "title-row "
            }
          >
            {this.props.flyout && (
              <div className="x-close-container ">
                {/* <span className="x-close" onClick={handleClose}>
                  X
                </span> */}
                <ArrowLeftCircle className="x-close" onClick={handleClose} />
              </div>
            )}

            <div>
              {this.props.bodyTitle}
              {this.props.bodySubtitle && <p>{this.props.bodySubtitle}</p>}
            </div>

            {!this.props.flyout && (
              // <span className="x-close x-right" onClick={handleClose}>
              //   X
              // </span>
              <ArrowLeftCircle className="x-close" onClick={handleClose} />
            )}
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div
          className={"modal " + this.props.mainClass}
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className={getBaseClass()}>
            <div className="modal-content">
              {this.props.title && header()}

              {this.props.bodyTitle && bodyHeader()}
              {this.props.body ? (
                <div className="modal-body">{this.props.body}</div>
              ) : (
                ""
              )}
              {this.props.children ? (
                <div className="modal-body">{this.props.children}</div>
              ) : (
                ""
              )}

              {!this.props.hideFooter && (
                <div className="modal-footer bg-light">
                  {this.props.cancelButtonText && (
                    <button
                      type="button"
                      className={
                        "btn " +
                        (this.props.cancelButtonClass || "btn-secondary")
                      }
                      onClick={() => {
                        this.props.onCancel?.();
                      }}
                    >
                      {this.props.cancelButtonText}
                    </button>
                  )}

                  {this.props.secondaryButtonText && (
                    <button
                      type="button"
                      disabled={this.props.secondaryButtonDisabled}
                      style={{ marginLeft: "auto" }}
                      className={
                        "btn " + (this.props.secondaryButtonClass || "btn-info")
                      }
                      onClick={() => {
                        this.props.onSecondary?.();
                      }}
                    >
                      {this.props.secondaryButtonIcon}
                      {this.props.secondaryButtonText}
                    </button>
                  )}

                  {this.props.mainButtonText && (
                    <button
                      type="button"
                      disabled={this.props.mainButtonDisabled}
                      style={{
                        marginLeft: this.props.secondaryButtonText
                          ? "10px"
                          : "auto",
                      }}
                      className={
                        "btn " + (this.props.mainButtonClass || "btn-primary")
                      }
                      onClick={() => {
                        this.props.onAccept?.();
                      }}
                    >
                      {this.props.mainBUttonIcon}
                      {this.props.mainButtonText}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </React.Fragment>
    );
  }
}

export default Modal;
