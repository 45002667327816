import { useState, useEffect, useContext } from "react";
import { Link, useLocation, withRouter } from "react-router-dom";
import { Student } from "../../../models/Student.model";
import studentService from "../../../services/student.service";
import StudentCard from "../../../components/student/card/studentCard";
import StudentCommunication from "../../../components/student/student-notes/studentCommunication";
import DocumentCheckList from "../../../components/student/documentCheckList/documentCheckList";

import "react-toastify/dist/ReactToastify.css";
import "./studentOverview.scss";
import globalContext from "../../../context/globalContext";
import ChecklistService from "../../../services/checklist.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { StudentChecklist } from "../../../models/Checklist.model";
import Modal from "../../../components/modal/modal";
import { useHistory } from "react-router-dom";
import TitleBar from "../../../components/shared/titleBar/titleBar";
import StudentSideMenu from "../../../components/student/studentSideMenu/studentSideMenu";
import StudentDocuments from "../../../components/student/documents/studentDocuments";
import { Permission } from "../../../models/Enums";
import CreateUploadRequestModal from "../../../components/upload-request/create-upload-request-modal/createUploadRequestModal";
import { StudentProgram } from "../../../models/Program.model";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import usePrevForms from "../../../hooks/prevForms";

interface LocationState {
  fromNotification?: boolean;
}

const StudentOverview = (props: any) => {
  const { clearAlert, setLoading, hasPermission } = useContext(globalContext);
  const { openPrevDialogForm, getPrevDialogForm, setStudentPrev } = usePrevForms();
  const location = useLocation<LocationState>();

  const [loaded, setLoaded] = useState(false);
  const [studentId, setStudentId] = useState<number>(0);
  const [student, setStudent] = useState<Student>(new Student());
  const [checklists, setChecklists] = useState<StudentChecklist[]>([]);
  const [notesVisible, setNotesVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [enableUploadRequest, setEnableUploadRequest] = useState(true);
  const [checklistsLoading, setChecklistsLoading] = useState(true);
  const [newRequestVisible, setNewRequestVisible] = useState(false);
  const [updateNotes, setUpdateNotes] = useState(false);
  const canViewEstimator = hasPermission(Permission.VIEW_ESTIMATE_TEMPLATES);
  const [program, setProgram] = useState<StudentProgram>();
  
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const history = useHistory();

  const loadData = async () => {
    setLoading(true);
    let id = props.match.params.id;
    setStudentId(id);

    // open messages if come of the notification
    if (location.state?.fromNotification) {
      setValue("2");
    }

    // load student data
    let studentRes = await studentService.getStudentById(id);
    if (studentRes?.status === HttpStatusCode.Ok) {
      setStudent(new Student(studentRes.data));
      setLoaded(true);
    }

    loadCheckLists(id);
    setLoading(false);

    // issue with old safari versions, loading spinner does not go away
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const loadCheckLists = async (id?: string) => {
    let checklistsRes = await ChecklistService.getStudentChecklists(
      id || studentId
    );
    if (checklistsRes?.status === HttpStatusCode.Ok) {
      setChecklists(checklistsRes.data as StudentChecklist[]);
      setChecklistsLoading(false);
    }
  };

  const handleDeleteStudent = async () => {
    let id = props.match.params.id;
    let response = await studentService.deleteStudent(id);
    console.log(response);
    if (response?.status === HttpStatusCode.Ok) {
      history.push("/student/list");
      history.go(0);
    }
  };

  const deleteStudentBody = () => {
    return (
      <>
        <h6>This can not be undone, the student data will be lost.</h6>
        <label>
          <input
            type="checkbox"
            onChange={(e) => setConfirmDelete(e.target.checked)}
          />{" "}
          I understand
        </label>
      </>
    );
  };

  const getProgram = async () => {
    if (!student.id) return;
    let response = await studentService.getStudentProgram(student.id);
    if (response?.status === HttpStatusCode.Ok) {
      setProgram(response.data);
    }
  };

  const updateProgram = async (programId: number) => {
    let resp = await studentService.updateStudentProgram(student.id, programId);

    if (resp?.status === HttpStatusCode.Ok) {
      getProgram();
    }
  };

  useEffect(() => {
    clearAlert();
    loadData();
  }, []);

  useEffect(() => {
    if (!student) return;
    getProgram();
  }, [student.id]);

  useEffect(() => {
    setStudentPrev(student);
  }, [student]);

  return (
    <div className="page page-student-overview ">
      <Modal
        visible={modalVisible}
        cancelButtonText="Cancel"
        mainButtonText="Delete Student"
        mainButtonClass="btn-danger"
        mainButtonDisabled={!confirmDelete}
        title="Are you sure you want to delete this student?"
        body={deleteStudentBody()}
        onCancel={() => {
          setModalVisible(false);
        }}
        onClose={() => {
          setModalVisible(false);
        }}
        onAccept={handleDeleteStudent}
      ></Modal>

      {getPrevDialogForm()}

      <CreateUploadRequestModal
        visible={newRequestVisible}
        studentId={studentId}
        onClose={() => setNewRequestVisible(false)}
        showStudentSearch={false}
      />

      <TitleBar
        header={loaded ? student.fullName() : "Loading..."}
        subheader={
          loaded
            ? student?.schoolData?.name || "⚠️ Warning: School not assigned"
            : ""
        }
      >
        {enableUploadRequest ? (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setNewRequestVisible(true)}
          >
            Document Request
          </button>
        ) : null}

        {canViewEstimator && (
          <Link
            className="btn btn-sm btn-outline-success mg-l-5"
            to={"/student/estimate/" + studentId}
          >
            Estimator
          </Link>
        )}

        <div className="btn-group mg-l-10">
          <button
            type="button"
            className="btn btn-sm btn-light dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Options
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href={"/student/edit/" + student.id} className="dropdown-item">
                Edit Student
              </a>
            </li>
            <li className="delete-option-container">
              <button
                className="dropdown-item"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                Delete Student
              </button>
            </li>
          </ul>
        </div>
      </TitleBar>

      <div className="col">
        <div className="row pd-xl-15 pd-md-10 pd-sm-0">
          <div className="col-sm-5 col-md-4 col-lg-3  ">
            <StudentCard
              student={student}
              program={program}
              updateProgram={updateProgram}
            />
            <StudentSideMenu
              student={student}
              setUpdateData={setUpdateNotes}
              program={program}
              openPrevModal={openPrevDialogForm}
            />
            {checklists && checklists.length > 0 && (
              <DocumentCheckList
                checklists={checklists}
                loading={checklistsLoading}
              ></DocumentCheckList>
            )}
          </div>

          <div className="col-sm-7 col-md-8 col-lg-9 overview-content">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="Student Documents and Feedback"
                >
                  <Tab label=" Documents " value="1" />
                  <Tab label=" Feedback " value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <StudentDocuments
                  student={student}
                  studentId={studentId}
                  onDocumentStatusChanged={() => loadCheckLists()}
                />
              </TabPanel>

              <TabPanel value="2">
                <StudentCommunication
                  student={student}
                  studentId={props.match.params.id}
                  setUpdateData={setUpdateNotes}
                  updateData={updateNotes}
                  visible={notesVisible}
                  onClose={() => setNotesVisible(false)}
                />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(StudentOverview);
