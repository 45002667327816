import "./email.scss";
import { Download } from "react-feather";

const Email = (props: any) => {
  const getFormattedDate = () => {
    return new Date(props.data.createdAt).toLocaleString();
  };

  const el = document.getElementById("notes-container"); // id of the chat container
  if (el) {
    el.scrollTop = el.scrollHeight;
  }

  const getCreator = () => {
    if (props.data.direction === 1) {
      return (
        <>
          Reply by student:{" "}
          <span className="creator">
            {props.data.student.firstName + " " + props.data.student.lastName}
          </span>
        </>
      );
    } else {
      return (
        <>
          Email sent by: <span className="creator">{props.data.creator}</span>
        </>
      );
    }
  };

  return (
    <div
      className={`card text-dark mb-3 email ${
        props.data.direction === 1 ? "email-incoming" : "email-outgoing"
      }`}
    >
      <div className="card-body">
        <div className="email-header">
          <label>{getFormattedDate()}</label>
          <label>{getCreator()}</label>
        </div>
        <div className="data">
          <label>{props.data.subject}</label>
        </div>
        <div
          id="notes-container"
          className="email-body"
          dangerouslySetInnerHTML={{ __html: props.data.body }}
        ></div>
        {/* <div className="download">
          <button className="button-d">
            <span className="button-text">Download.pdf</span>
            <span className="button-icon">
              <Download className="svg" />
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Email;
