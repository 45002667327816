import commonService from "../../../services/common.service";
import globalContext from "../../../context/globalContext";
import RadioButton from "../../../components/shared/Input/radioButton";
import applicationService from "../../../services/applicationRequest.service";
import TextField from "../../../components/shared/Input/textField";
import SelectField from "../../../components/shared/Input/selectField";
import SignaturePad from "react-signature-canvas";
import { ApplicationForm } from "../../../models/ApplicationForm.model";

import { ApplicationStatus, SchoolID, StudentStatus } from "../../../models/Enums";
import { CheckboxList } from "../../../components/shared/Input/checkBox";
import { State } from "../../../models/common/State.model";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertType } from "../../../models/AlertType.enum";
import studentService from "../../../services/student.service";
import { HttpStatusCode } from "../../../models/common/HttpStatusCode.enum";
import { UserMessages } from "../../../components/shared/constants/constants";

import dateService from "../../../services/date.service";

import { raceOptions } from "../commonOptions";
import { preferredPronounOptions, checkList, privacyCheckList, maritalStatusOptions, educationLevelOptions } from "./cobaOptions";
import { Program, ProgramStartDate } from "../../../models/Program.model";
import programsService from "../../../services/programs.service";
import moment from "moment";
import { MASKS, PATTERNS } from "../../../components/shared/Input/patterns";

import "./coba.scss";
import PhoneField from "../../../components/shared/Input/phoneField/phoneField";

const Coba = (props: any) => {
  let sigCanvas: any = useRef(null);
  const { clearAlert, setLoading, showAlert } = useContext(globalContext);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setValue,
    control,
    watch,
  } = useForm<ApplicationForm | any>();

  const [states, setStates] = useState<State[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<Program>();
  const [startDates, setStartDates] = useState([] as any);
  const [shifts, setShifts] = useState([] as any);
  const [modalVisible, setModalVisible] = useState(false);
  const [form, setForm] = useState(new ApplicationForm());
  const [formId, setFormId] = useState(0);
  const [editing, setEditing] = useState(false);
  const [editingToken, setEditingToken] = useState("");
  const [queryString, setQueryString] = useState("");
  const [signatureError, setSignatureError] = useState(false);
  const [duplicatedEmail, setDuplicatedEmail] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const isCitizen = watch("citizenship.citizen");
  const isEmployed = watch("currentlyEmployed");
  const edLevel = watch("student.educationLevel");

  const handleSignatureClear = () => {
    getSignature().clear();
  };

  const loadInitialData = async () => {
    let statesReq = commonService.getStates();
    let programsReq = programsService.getProgramsBySchool(SchoolID.COBA);
    let startDateReq = programsService.getStartDatesBySchool(SchoolID.COBA);
    let shiftReq = programsService.getShiftsBySchool(SchoolID.COBA);

    setLoading(true, "Loading your data...");
    let [statesRes, programsRes, startDateRes, shiftsRes] = await Promise.all([statesReq, programsReq, startDateReq, shiftReq]);
    setLoading(false);
    setStates(statesRes);
    setPrograms(programsRes?.data ?? []);
    setStartDates(startDateRes.map((record: any) => ({ ...record, date: dateService.formatYMD(record.startDate) })) ?? []);
    setShifts(shiftsRes ?? []);
  };

  const getStartDates = () => {
    const programId = selectedProgram?.id;
    if (startDates.length > 0 && programId) {
      const pivot = moment().add("months");
      return startDates
        .filter((x: ProgramStartDate) => x.programId === programId && moment(x.startDate).isAfter(pivot))
        .slice(0, 3)
        .map((x: ProgramStartDate) => {
          return { value: x.startDate, label: x.startDate };
        });
    }
    return [];
  };

  const getShifts = () => {
    const programId = selectedProgram?.id;
    if (shifts.length > 0 && programId) {
      return shifts
        .filter((x: any) => x.programId === programId)
        .map((x: any) => {
          return { value: x.name, label: x.name };
        });
    }
    return [];
  };

  const handleModalClose = () => {
    setModalVisible(false);
    props.history.push(`/public/COBA/thank_you`);
  };

  const calculateAge = (e: any) => {
    let dob = e.target.value;
    if (dob) {
      let date = new Date(dob);
      let ageDifMs = Date.now() - date.getTime();
      let ageDate = new Date(ageDifMs);
      setValue("student.age", Math.abs(ageDate.getUTCFullYear() - 1970));
    }
  };

  const getSignature = () => {
    return sigCanvas.current;
  };

  const moveToNextForm = (studentId: any, token: any) => {
    window.scrollTo(0, 0);
    props.history.push(`/public/forms/coba/factsheet?id=${studentId}&token=${token}`);
    return null;
  };

  const handleSave = async (data: any) => {
    clearAlert();

    let sign = getSignature();
    if (sign.isEmpty()) {
      setSignatureError(true);
      return;
    }

    setIsButtonClicked(true);


    setSignatureError(false);
    let payload = { ...data } as ApplicationForm;
    payload.signature = sign.toDataURL();
    payload.student.school = SchoolID.COBA;
    payload.student.status = StudentStatus.APPLICATION_REQUEST;
    payload.student.startDate = moment(payload.startDate).format("YYYY-MM-DD");
    payload.student.numberChildren = payload.student.numberChildren || 0;
    payload.status = ApplicationStatus.SUBMITTED;
    if (payload.student.preferredPronoun === "Other") {
      payload.student.preferredPronoun = (payload.student as any)?.preferredPronounOther;
    }
    // delete the other as is not expected on backend, it is just temporal to FE
    delete (payload.student as any)?.preferredPronounOther;

    setLoading(true, "Saving data, please wait");

    try {
      // if (editing) { // editing not supported yet - backend needed
      //   payload.id = formId;
      //   // TODO: when supporting editing, the following endpoint only works for SDGKU
      //   let resp = await applicationService.updateApplicationRequest(payload, editingToken);
      //   if (resp?.status === 200) {
      //     setQueryString(`first=${payload.student.firstName}&last=${payload.student.lastName}&token=${editingToken}`);
      //     reset(); // reset form
      //     setModalVisible(true);
      //     // moveToNextForm();
      //   } else {
      //     showAlert("Error updating application", AlertType.Error);
      //   }
      // } else {
      let response = await applicationService.saveApplicationRequest(payload);
      if (response) {
        //setFormId(response.id);
        //setQueryString(`first=${payload.student.firstName}&last=${payload.student.lastName}`);
        reset(); // reset form
        moveToNextForm(response.student.id, response.token);
      }
      // }
    } catch (e) {
      console.log("error saving data", e);
    } finally {
      setLoading(false);
      setIsButtonClicked(false);
    }
  };

  const handleEmailChanged = async (e: any) => {
    let response = await studentService.validateEmail(e.target.value);
    if (response?.status !== HttpStatusCode.Ok) {
      setDuplicatedEmail(true);
      showAlert(UserMessages.errors.student_duplicated_email, AlertType.Error);
    } else {
      setDuplicatedEmail(false);
      clearAlert();
    }
  };

  useEffect(() => {
    setForm({ ...form, academicInfo: {} as any });
    loadInitialData();
  }, []);

  const checkKeyDown = (e: any) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const durationName = (p: Program) => {
    if (p.unit === "months") {
      if (p.length === 50) {
        return "4 year 2 months";
      } else if (p.length === 18) {
        return "1 year 6 months";
      }
    }

    return `${p["length"]} ${p["unit"]}`;
  };

  const handleProgramChange = (e: any) => {
    setSelectedProgram(e);
    if (e?.id) {
      setForm((prevState) => ({ ...prevState, courseLength: e.duration, startDate: "", shift: "" }));
    }
  };

  const getProgramsToDisplay = () => {
    return programs.map((p) => ({ id: p.id, value: p.name, label: p.name, duration: durationName(p) }));
  };

  const isGED = () => edLevel === "GED";
  const isHighSchool = () => edLevel === "High School";

  return (
    <div className="form-a">
      <form method="POST" onKeyDown={(e) => checkKeyDown(e)} className="preapp-form container coba-form">
        <h2>
          <img src="/static/coba/logo-coba.png" alt="coba logo"></img>
          <p className="coba-title">ONLINE ADMISSIONS APPLICATION</p>
        </h2>

        <h3>Personal Information</h3>

        <div className="three-columns border-bottom">
          <TextField
            parentClass="form-group"
            field="student.firstName"
            maxLength="70"
            type="text"
            label="First name"
            placeholder="Enter your first name"
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.middleName"
            maxLength="70"
            type="text"
            label="Middle name"
            placeholder="Enter your middle name"
            register={register}
          />

          <TextField
            parentClass="form-group"
            field="student.lastName"
            maxLength="70"
            type="text"
            label="Last name"
            placeholder="Enter your last name"
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.email"
            maxLength="70"
            type="text"
            label="Email"
            placeholder="Enter your email"
            register={register}
            errors={errors}
            onBlur={handleEmailChanged}
            rules={{
              required: true,
              pattern: PATTERNS.emailRegEx,
            }}
          />

          <SelectField
            field="student.gender"
            label="Gender"
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={[
              { label: "Male", value: 1 },
              { label: "Female", value: 2 },
              { label: "Prefer not to say", value: 3 },
            ]}
          />

          <SelectField
            field="student.preferredPronoun"
            label="Preferred Pronoun:"
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={preferredPronounOptions.map((p) => ({ label: p, value: p }))}
          />


          <PhoneField
            parentClass="form-group"
            field="student.phone"
            type="text"
            label="Telephone"
            placeholder="Enter your telephone"
            control={control}
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.birthDate"
            type="date"
            label="Date of Birth"
            onChange={calculateAge}
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.age"
            type="number"
            label="Age"
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <SelectField
            field="student.maritalStatus"
            label="Marital Status"
            errors={errors}
            control={control}
            options={maritalStatusOptions}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.numberChildren"
            type="number"
            label="Number of children"
            placeholder="Number of children, if any"
            register={register}
            errors={errors}
          />
        </div>

        <div className="three-columns mg-t-10">
          <TextField
            parentClass="form-group span-1-3"
            field="student.addressStreet"
            maxLength="100"
            type="text"
            label="Address"
            placeholder="Enter your street"
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <TextField
            parentClass="form-group"
            field="student.addressNumber"
            maxLength="10"
            type="text"
            label="Apt./Unit Number"
            placeholder="Number"
            register={register}
          />

          <TextField
            parentClass="form-group"
            field="student.addressCity"
            maxLength="100"
            type="text"
            label="City"
            placeholder="Enter your city"
            register={register}
            errors={errors}
            rules={{ required: true }}
          />

          <SelectField
            field="student.addressState"
            label="State"
            errors={errors}
            control={control}
            rules={{ required: true }}
            labelKey="name"
            valueKey="id"
            options={states}
          />

          <TextField
            parentClass="form-group"
            field="student.addressZip"
            maxLength="10"
            type="text"
            label="Zip Code"
            placeholder="Enter your zip code"
            register={register}
            errors={errors}
            rules={{ required: true, pattern: PATTERNS.zipCodeRegEx }}
            mask={MASKS.zipCodeMask}
          />
        </div>

        <div className="three-columns mg-t-10">
          <div>
            <TextField
              parentClass="form-group mg-t-10 inner-input-container"
              field="student.ssn"
              type="text"
              label="Social Security Number"
              mask={MASKS.ssnMask}
              register={register}
              rules={{
                required: true,
                pattern: PATTERNS.ssnRegEx,
              }}
            />
          </div>

          <div className="span-2-4">
            <label>
              Are you left-handed? <br /> <span>*Applicable to Cosmetology and Barber Perspective</span>
            </label>
            <div className="radio-container">
              <RadioButton field="student.leftHanded" label="Yes" value="1" register={register}></RadioButton>
              <RadioButton field="student.leftHanded" label="No" value="0" register={register}></RadioButton>
            </div>
          </div>
        </div>

        <label className="mg-t-30 mg-b-10">
          Race/Ethnicity information is optional. Any information you provide will not be used for discriminatory purposes.
        </label>
        <div className="two-columns">
          <SelectField
            field="race"
            label="What's your ethnicity or racial background?"
            includeColon={false}
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={raceOptions}
          />

          <SelectField
            field="ethnicity"
            label="Are you Hispanic or Latino?"
            includeColon={false}
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={[
              { value: "Hispanic Latino", label: "Yes" },
              { value: "Not Hispanic", label: "No" },
            ]}
          />
        </div>

        <h3>Citizenship</h3>
        <div className="three-columns">
          <div className="form-group">
            <label>U.S. Citizen?</label>
            <div className="radio-container">
              <RadioButton
                field="citizenship.citizen"
                label="Yes"
                value="1"
                register={register}
                errors={errors}
                rules={{ required: true }}
              ></RadioButton>
              <RadioButton
                field="citizenship.citizen"
                label="No"
                value="0"
                register={register}
                errors={errors}
                rules={{ required: true }}
              ></RadioButton>
            </div>
          </div>

          {isCitizen === "0" && (
            <div className="form-group">
              <label>Do you have a Permanent Residency card?</label>
              <div className="radio-container">
                <RadioButton
                  field="citizenship.visa"
                  label="Yes"
                  value="1"
                  register={register}
                  errors={errors}
                  rules={{ required: isCitizen === "0" }}
                ></RadioButton>
                <RadioButton
                  field="citizenship.visa"
                  label="No"
                  value="0"
                  register={register}
                  errors={errors}
                  rules={{ required: isCitizen === "0" }}
                ></RadioButton>
              </div>
            </div>
          )}
        </div>

        <h3>Program</h3>
        <div className="three-columns">
          <SelectField
            parentClass="form-group span-1-3"
            field="program"
            label="Intended Program of Study"
            errors={errors}
            control={control}
            rules={{ required: true }}
            onChange={handleProgramChange}
            options={getProgramsToDisplay()}
          />

          <TextField
            field="courseLength"
            type="text"
            label="Program length"
            placeholder=""
            register={register}
            disabled={true}
            value={form?.courseLength || ""}
            errors={errors}
          />

          <SelectField
            field="startDate"
            label="Intended Start Date"
            errors={errors}
            control={control}
            options={getStartDates()}
            rules={{ required: true }}
          />

          <SelectField field="shift" label="Schedule" errors={errors} control={control} options={getShifts()} rules={{ required: true }} />
        </div>

        <div className="one-column">
          <TextField
            parentClass="form-group"
            field="hearAboutUs"
            maxLength="100"
            type="text"
            label="How did you hear about us?"
            placeholder="How did you hear about us?"
            register={register}
            errors={errors}
          />
          <div>
            <label>Do you have a criminal record?
              <br /> <span>*By selecting 'Yes' you will be sent to the email provided, a BBC Convictions Disclosure Form with directions for completion.</span>
            </label>
            <RadioButton
              field="criminalRecord"
              label="Yes"
              value="1"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
            <RadioButton
              field="criminalRecord"
              label="No"
              value="0"
              register={register}
              errors={errors}
              rules={{ required: true }}
            />
          </div>
        </div>

        <h3>Employment and Education</h3>
        <div className="three-columns">
          <div>
            <label>Are you currently employed?</label>
            <RadioButton
              field="currentlyEmployed"
              label="Yes"
              value="1"
              register={register}
              errors={errors}
              rules={{ required: true }}
            ></RadioButton>
            <RadioButton
              field="currentlyEmployed"
              label="No"
              value="0"
              register={register}
              errors={errors}
              rules={{ required: true }}
            ></RadioButton>
          </div>

          <TextField
            parentClass="form-group"
            field="employedWhere"
            maxLength="200"
            type="text"
            label="Where"
            visible={isEmployed === "1"}
            placeholder="Enter your employment"
            register={register}
            errors={errors}
            rules={{ required: isEmployed === "1" }}
          />
        </div>

        <div className="three-columns mg-t-20">
          <SelectField
            field="student.educationLevel"
            label="Highest level of education"
            errors={errors}
            control={control}
            rules={{ required: true }}
            options={educationLevelOptions}
          />

          <TextField
            field="academicInfo.highName"
            maxLength="150"
            parentClass="form-group"
            type="text"
            visible={!isGED()}
            label={(isHighSchool() ? "High " : "") + "School Name"}
            rules={{ required: !isGED() }}
            errors={errors}
            register={register}
          />

          <TextField
            field="academicInfo.highYear"
            maxLength="150"
            parentClass="form-group"
            type="text"
            label={isGED() ? "GED Achieved Year" : "Graduation mm/yyyy"}
            rules={{ required: true }}
            errors={errors}
            register={register}
          />
        </div>

        <div className="one-column">
          <TextField
            field="academicInfo.highAddress"
            maxLength="150"
            parentClass="form-group span-1-3"
            type="text"
            visible={!isGED()}
            label="School Address (Street Number and Name, City, State, Zip Code, Country)"
            rules={{ required: !isGED() }}
            errors={errors}
            register={register}
          />
        </div>

        <h3>
          <span className="required">*</span> Method of Payment <span>(Select all that apply)</span>
        </h3>

        <div className="one-two-thirds-columns">
          <div className="form-group">
            <label>How do you plan to finance your education?</label>
            <div className="checks-group-payment row">
              <CheckboxList items={checkList} getValues={getValues} register={register} errors={errors} rules={{ oneRequired: true }} />
            </div>
          </div>
        </div>

        <h6 className="label-certify mg-b-20">
          <div className="form-group">
            <label> </label>
            <div className="checks-group-payment row">
              <CheckboxList
                items={privacyCheckList}
                getValues={getValues}
                register={register}
                errors={errors}
                isDirty={isDirty}
                rules={{ allRequired: true }}
              />
            </div>
          </div>
        </h6>

        <div className="two-columns">
          <div className="form-group sig-pad">
            <label className="form-check-label">
              <span className="required">*</span>Please add your Signature
            </label>

            <SignaturePad ref={sigCanvas} canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}></SignaturePad>
          </div>
          <div className="clear-container">
            <button type="button" className="btn btn-dark" onClick={handleSignatureClear}>
              Clear Signature
            </button>
            {signatureError ? <p className="invalid-input-error">* Please sign the application</p> : null}
          </div>
        </div>

        <div className="save-container">
          <button
            type="submit"
            disabled={duplicatedEmail || isButtonClicked}
            className="btn btn-primary"
            onClick={handleSubmit((d) => handleSave(d))}
          >
            {duplicatedEmail ? "Email already exist" : "Submit Application Request"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Coba;
