import './studentDocuments.scss';
import { useState, useEffect, useContext, useMemo } from 'react';
import documentService from '../../../services/document.service';
import globalContext from '../../../context/globalContext';
import { Document } from '../../../models/Document.model';
import { DocumentStatus, DocumentUploadType, Permission } from '../../../models/Enums';
import { HttpStatusCode } from '../../../models/common/HttpStatusCode.enum';
import { toast } from 'react-toastify';
import Table from '../../shared/table/table';
import { optionsCellWithEdit } from '../../shared/table/tableCols';
import { Eye as IconEye, EyeOff as IconEyeOff } from 'react-feather';
import Modal from '../../../components/modal/modal';
import ZipFileModal from '../../../components/zipFileModal/zipFileModal';
import StudentDocumentUpload from '../documentUpload/studentDocumentUpload';
import ActionIcon from '../../shared/action-icon/actionIcon';
import Select from 'react-select';
import { Download as IconDownload, XCircle as IconReject, FilePlus as IconZip, CheckCircle as IconApprove, Send as IconSend } from 'react-feather';
import EmailDocumentModal from '../emailDocumentModal/emailDocumentModal';
import { Student } from '../../../models/Student.model';
import PDFViewer from '../../pdfViewer/pdfViewer';
import ImageViewer from '../../imageViewer/imageViewer';

export interface StudentDocumentsProps {
  student: Student;
  studentId: number;
  onDocumentStatusChanged: (document?: Document) => void;
}

const StudentDocuments = (props: StudentDocumentsProps) => {
  const context = useContext(globalContext);

  const [showAlert, hideAlert] = [context.showAlert, context.clearAlert];
  const [selectedOption, setSelectedOption] = useState(null as any);
  const [docTypeOptions, setDocTypeOptions] = useState([] as any);
  const [selectedDoc, setSelectedDoc] = useState({} as File);
  const [status, setStatus] = useState(0);
  const [documents, setDocuments] = useState([] as Document[]);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [zipVisible, setZipVisible] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([] as number[]);
  const [approveVisible, setApproveVisible] = useState(false);
  const [emailDocumentVisible, setEmailDocumentVisible] = useState(false);
  const canApproveDocs = context.hasPermission(Permission.APPROVE_DOCUMENTS);
  const [viewingPdf, setViewingPdf] = useState('');
  const [viewingImage, setViewingImage] = useState('');
  const [viewingImageTitle, setViewingImageTitle] = useState('');
  const [documentPage,setDocumentPage] = useState();

  const handleUpload = async () => {
    if (!validate()) return;
    setStatus(1); // show loading

    let payLoad = {
      documentType: selectedOption.value,
      uploadType: DocumentUploadType.MANUAL_UPLOAD,
      file: selectedDoc,
      fileName: selectedDoc.name,
      fileSize: selectedDoc.size,
      student: props.studentId,
      status: DocumentStatus.PENDING,
    } as Document;

    let response = await documentService.uploadStudentDocument(payLoad);

    setStatus(0); // hide loading
    if (response?.status === HttpStatusCode.Created) {
      setDocuments([mapDocumentDateAndLink(response?.data), ...documents]);
    }
    processResponse(response, '✔️ Document Uploaded', '❌ Upload failed');
  };

  const handleDownloadForm = (file: any, fileName: string, documentType: any) => {    
    if (file.includes('pdf') || file.includes('api/reports/application_requests/')) {
      setViewingPdf(file);
    }
    else if (file.includes('jpg') || file.includes('png') || file.includes('jpeg')) {    
      setViewingImage(file);
      setViewingImageTitle("[" + documentType.name + "] " + fileName);
    } else {
      // download
      window.open('' + file, 'newwindow', 'width=820px,height=950px');
      return false;
    }
  };

  const handleApproveVisible = (documentId: number) => {
    setCurrentDocId(documentId);
    setApproveVisible(true);
  };

  const handleApprove = async () => {
    if (!currentDocId) return;

    setApproveVisible(false);
    context.setLoading(true, 'Please wait...');
    let response = await documentService.approveDocument(currentDocId);
    context.setLoading(false);

    if (response?.status === HttpStatusCode.Ok) {
      let copy = [...documents];
      let document = copy.find((doc: any) => doc.id === currentDocId);
      if (document) {
        document.status = DocumentStatus.APPROVED;
      }
      setDocuments(copy);
      setCurrentDocId(0);
      props.onDocumentStatusChanged?.(document);
    }

    processResponse(response, '✔️ Document approved', '❌ Approval failed');
  };

  const handleDocTypeChanged = async (changeRow: any) => {
    if (!changeRow.rowId) return;

    context.setLoading(true, 'Please wait...');
    let response = await documentService.changeDocumentType(changeRow.rowId, changeRow.documentType.value);
    context.setLoading(false);

    if (response?.status === HttpStatusCode.Ok) {
      let copy = [...documents];
      let document = copy.find((doc: any) => doc.id === changeRow.rowId);
      if (document) {
        document.documentType = response.data.documentType;
      }
      setDocuments(copy);
    }

    processResponse(response, '✔️ Document updated', '❌ Document update failed');
  };

  const handleShowModal = (documentId: number) => {
    setCurrentDocId(documentId);
    setModalVisible(true);
  };

  const handleDelete = async () => {
    if (!currentDocId) return;
    setModalVisible(false);
    let response = await documentService.deactivateDocument(currentDocId);
    if (response?.status === HttpStatusCode.Ok) {
      setDocuments(documents.filter((doc: any) => doc.id !== currentDocId));
      setCurrentDocId(0);
    }
    processResponse(response, '✔️ Document Deleted', '❌ Failed to delete document');
  };

  const handleCheckBoxChange = (event: any) => {
    const id = parseInt(event.target.id);
    const checkState = event.target.checked;

    var docsCopy = [...documents];

    docsCopy.find((doc) => doc.id === id)!.selected = checkState;
    setDocuments(docsCopy);

    var current = [...selectedFiles];
    if (checkState) {
      current.push(id);
    } else {
      current.splice(current.indexOf(id), 1);
    }

    setSelectedFiles(current);
    
  };

  const handleClearSelection = () => {
    var copy = documents.map((doc) => ({ ...doc, selected: false }));
    setDocuments(copy);
    setSelectedFiles([]);
  };

  const handleZip = async (response: any) => {
    setZipVisible(false);
    if (response?.status === HttpStatusCode.Ok) {
      let docsCopy = [mapDocumentDateAndLink(response?.data), ...documents]; // insert new doc (zip)
      docsCopy = docsCopy.map((doc) => ({ ...doc, selected: false })); // unselect everything
      setDocuments(docsCopy);
      setSelectedFiles([]);
    }
    processResponse(response, '✔️ Files Zipped', '❌ Zip failed');
  };

  const processResponse = (response: any, success: string, alert: string) => {
    setSelectedDoc({} as File);
    if (response?.status === HttpStatusCode.Created || response?.status === HttpStatusCode.Ok) {
      hideAlert();
      toast.dark(success, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast.error(alert, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const validate = () => {
    if (!selectedOption) {
      showAlert('Please select document type');
      return false;
    }

    if (!selectedDoc) {
      showAlert('Please select file');
      return false;
    }

    hideAlert();
    return true;
  };

  const mapDocumentDateAndLink = (doc: any) => {
    if (doc.auto) {
      // add the link to the mock Admissions form
      doc.file = '/api/reports/application_requests/' + doc.id;
      doc.id = -1;
    }

    return {
      ...doc,
      createdAt: new Date(doc?.createdAt).toLocaleDateString(),
    };
  };

  const loadCurrentDocuments = async () => {
    if (!props.studentId) return;

    const docs = await documentService.getDocumentsByStudent(props.studentId);
    if (docs?.status === HttpStatusCode.Ok) {
      setDocuments(docs?.data.map(mapDocumentDateAndLink));
    }
  };

  const loadDocTypes = async () => {
    const docTypes = await context.loaders.documentTypes();
    setDocTypeOptions(docTypes.map((x: any) => ({ value: x.id, label: x.name })));
  };

  const getTableRowProps = (row: any) => {
    if (row.values.status === DocumentStatus.PENDING) {
      return {
        style: {
          backgroundColor: 'lightyellow',
        },
      };
    }
  };

  useEffect(() => {
    loadDocTypes();
  }, []);

  useEffect(() => {
    loadCurrentDocuments();
  }, [props?.studentId]); // if this change, load data again

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        hidden: true,
      },
      {
        Header: 'Select',
        accessor: 'selected',
        Cell: ({ cell, onCheckChange }: { cell: any; onCheckChange: any }) => (
          <>
            {cell.row.values.id < 1 ? null : (
              <div className="col-md">
                <input type="checkbox" className="big" id={cell.row.values.id} value={cell.row.index} checked={cell.value} onClick={(arg) => onCheckChange(arg)} />
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'File Name',
        accessor: 'fileName',
      },
      {
        Header: 'Type',
        accessor: 'documentType',
        Cell: ({ cell, row, registerChange }: { cell: any; row: any; registerChange: any }) => {
          if (!row.editing) {
            return <>{cell.value?.name}</>;
          }

          return (
            <>
              <Select
                className="flex-grow-1"
                placeholder="Select document type"
                onChange={(val: any) => {
                  registerChange(row, 'documentType', val);
                }}
                options={docTypeOptions}
              />
            </>
          );
        },
      },
      {
        Header: 'Uploaded',
        accessor: 'createdAt',
      },
      {
        Header: 'Options',
        headerStyle: { textAlign: 'center' },
        accessor: 'file',
        className: 'col-options',
        classNameEditing: 'col-options-editing',
        Cell: (item: any) =>
          optionsCellWithEdit({
            item,
            normalPreEdit: (
              <>
                {item.cell.row.values.status === DocumentStatus.PENDING && canApproveDocs ? (
                  <ActionIcon label="Approve" tooltip="Approve Document" icon={<IconApprove />} color="primary" onClick={() => handleApproveVisible(item.cell.row.values.id)} />
                ) : null}

                <ActionIcon
                  label="Download"
                  tooltip="Download application"
                  icon={<IconDownload />}
                  color="primary"
                  extraClass={item.cell.row.values.status === DocumentStatus.PENDING ? 'download-action' : 'download-action_double'}
                  onClick={() => handleDownloadForm(item.cell.value, item.cell.row.values.fileName, item.cell.row.values.documentType)}
                />
              </>
            ),
            normalPostEdit: <>{item.cell.row.values.id < 1 ? null : <ActionIcon label="Delete" tooltip="Delete Document" icon={<IconReject />} color="danger" onClick={() => handleShowModal(item.cell.row.values.id)} />}</>,
            onAccept: (changedRow) => item.onChangesAccepted(changedRow),
          }),
      },
    ],
    [docTypeOptions]
  );

  //get all non aprroved
  let nonAprroved = documents.filter((d) => d.status === 1);
  // sort by date
  let sortByDate = documents
    .filter((d) => d.status !== 1)
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      if (dateA < dateB) {
        return 1;
      }
      if (dateA < dateB) {
        return -1;
      }
      return 0;
    });
  // Combine these two arrays
  let finalSort = nonAprroved.concat(sortByDate);

  return (
    <div className="student-documents">
      <Modal
        visible={modalVisible}
        cancelButtonText="Cancel"
        mainButtonText="Delete Document"
        mainButtonClass="btn-danger"
        title="Do you want to delete this document?"
        onCancel={() => {
          setModalVisible(false);
        }}
        onClose={() => {
          setModalVisible(false);
        }}
        onAccept={handleDelete}
      />

      <Modal
        visible={approveVisible}
        cancelButtonText="Cancel"
        mainButtonText="Approve Document"
        mainButtonClass="btn-success"
        title="Do you want to approve this document?"
        onCancel={() => {
          setApproveVisible(false);
        }}
        onClose={() => {
          setApproveVisible(false);
        }}
        onAccept={handleApprove}
      />

      <PDFViewer pdfUrl={viewingPdf} onClose={() => setViewingPdf('')} />
      <ImageViewer imageUrl={viewingImage} title={viewingImageTitle} onClose={() => setViewingImage('')} />

      {uploadVisible && (
        <StudentDocumentUpload
          uploadVisible={true}
          uploadEnabled={selectedOption && selectedDoc?.name}
          selectedDocType={selectedOption}
          status={status}
          onDocTypeChange={setSelectedOption}
          onFileChange={(doc) => {
            setSelectedDoc(doc);
          }}
          onFileUpload={handleUpload}
        />
      )}

      <ZipFileModal studentId={props.studentId} zipVisible={zipVisible} onZip={handleZip} getSelectedFiles={() => selectedFiles} onClose={() => setZipVisible(false)} />

      <EmailDocumentModal
        student={props.student}
        visible={emailDocumentVisible}
        close={() => {
          setEmailDocumentVisible(false);
        }}
        selectedDocuments={selectedFiles}
        documents={documents}
      />

      <div className="row student-documents-list">
        <div className="col-md-12 row-actions">
          {selectedFiles.length > 1 ? (
            <>
              <button
                onClick={() => {
                  setZipVisible(!zipVisible);
                }}
                className="btn btn-sm btn-success"
              >
                <IconZip />
                &nbsp;Zip Documents
              </button>
            </>
          ) : null}

          {selectedFiles.length > 0 && (
            <>
              <button
                onClick={() => {
                  setEmailDocumentVisible(!emailDocumentVisible);
                }}
                className="btn btn-sm btn-success"
              >
                <IconSend />
                &nbsp; Send Documents
              </button>
              
              <button
                onClick={() => {
                  handleClearSelection();
                }}
                className="clearSelection btn btn-link"
              >
                Clear Selection
              </button>
            </>
          )}

          <button
            onClick={() => {
              setUploadVisible(!uploadVisible);
            }}
            className="btn btn-sm btn-primary"
          >
            {uploadVisible ? <IconEyeOff /> : <IconEye />}
            {uploadVisible ? ' Hide Upload' : ' Upload New Document'}
          </button>
        </div>

        <div className="col-md-12 table-container">
          <Table
            getTrProps={getTableRowProps as any}
            columns={columns}
            data={finalSort}
            documentPage={documentPage}
            setDocumentPage={setDocumentPage}
            instanceFns={{
              onCheckChange: handleCheckBoxChange,
              onChangesAccepted: handleDocTypeChanged,
            }}
          ></Table>
        </div>
      </div>
    </div>
  );
};

export default StudentDocuments;
