import { useContext, useEffect, useState } from 'react';
import './enrollmentWizard.scss';
import globalContext from '../../../../context/globalContext';
import { AlertType } from '../../../../models/AlertType.enum';
import signedFormService from '../../../../services/signedForm.service';
import { TokenError, WizardCompletedPage } from '../enrollment/sdgku.extrapages';
import { Header } from '../header/sdgku.header';
import { Circle as ICircle, CheckCircle as ICheck, RefreshCw as ISpinner } from 'react-feather';
import { SignedFormKey } from '../../../../models/Forms.enum';
import { HttpStatusCode } from '../../../../models/common/HttpStatusCode.enum';
import { EnrollmentStatus } from '../../../../models/Forms.model';
import { SDGKUProgram } from '../../../../models/Enums';

interface Props {
  location: any;
}

enum WizardStep {
  None = 0,
  FactSheet = 1,
  VIB = 2,
  Catalog = 3,
  Transcripts = 4,
  Enrollment = 5,
  Disclosure = 6,
  HybridStatement = 7,
  BenefitCoding = 8,
  TranscriptEvaluation = 9,
}

export const EnrollmentWizard = ({ location }: Props) => {
  const defaultInProgressText = ' (in progress, close the other tab when you finish)';
  const requiredSteps = 6;

  const { setLoading, showAlert } = useContext(globalContext);

  const [token, setToken] = useState<string>(null!);
  const [studentId, setStudentId] = useState<string>(null!);
  const [tokenError, setTokenError] = useState(false);
  const [studentName, setStudentName] = useState<string>('Loading data...');
  const [isVA, setIsVA] = useState<boolean>(false);
  const [completedSteps, setCompletedSteps] = useState<WizardStep[]>([]);
  const [inProgress, setInProgress] = useState<WizardStep>(WizardStep.None);
  const [inProgressText, setInProgressText] = useState<string>(defaultInProgressText);
  const [showTryNote, setShowTryNote] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [isHybrid, setIsHybrid] = useState<boolean>(true);
  const queryParams = location.search;

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    // get token from url params
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const id = params.get('id') || "0";

    if (token) {
      setToken(token);
      setStudentId(id);
      loadEnrollmentStatus(token, id);
    } else {
      showAlert('Invalid token', AlertType.Error);
      setTokenError(true);
    }
  };

  const inProgressCompleted = (data: EnrollmentStatus, step: WizardStep) => {
    switch (step) {
      case WizardStep.FactSheet:
        return data?.factSheet;
      case WizardStep.VIB:
        return data?.vib;
      case WizardStep.Enrollment:
        return data?.enrollment;
      case WizardStep.Disclosure:
        return data?.disclosure;
      case WizardStep.Transcripts:
        return data?.trascriptsCommitment;
      case WizardStep.HybridStatement:
        return data?.hybridDisclosure;
      case WizardStep.TranscriptEvaluation:
        return data?.transcriptEvaluationRequest;
      default:
        return true;
    }
  };

  const addCompletedStep = (step: WizardStep) => {
    // add if doesn't exist
    if (!completedSteps.includes(step)) {
      setCompletedSteps((prevCompletedSteps) => [...prevCompletedSteps, step]);
    }
  };

  const validateEnrollmentWasCompleted = async (step: WizardStep, currentAttempt: number) => {
    const resp = await signedFormService.checkEnrollmentStatus(studentId, token);

    if (resp?.status === HttpStatusCode.Ok) {
      const data = resp.data;
      console.log('validateEnrollmentWasCompleted', data);
      // there is a form in progress, verify is the backend marked it as completed
      if (inProgressCompleted(data, step)) {
        addCompletedStep(step);
        setInProgress(WizardStep.None);
        setInProgressText(defaultInProgressText);
      } else {
        if (currentAttempt <= 5) {
          // not completed on server, try again in 5 seconds
          setTimeout(() => {
            validateEnrollmentWasCompleted(step, currentAttempt + 1);
          }, 4000 + currentAttempt * 1000);
        } else {
          setShowTryNote(true);
          setInProgress(WizardStep.None);
          setInProgressText(defaultInProgressText);
          setCompletedSteps((prevCompletedSteps) => [...prevCompletedSteps.filter((s) => s !== step)]);
        }
      }
    }
  };

  const loadEnrollmentStatus = async (token: string, studentId: string) => {
    setLoading(true);
    const resp = await signedFormService.checkEnrollmentStatus(studentId, token);
    setLoading(false);

    if (resp?.status === HttpStatusCode.Ok) {
      let data = resp.data;
      let completedDocs = completedSteps;
      if (data?.disclosure) {
        completedDocs.push(WizardStep.Disclosure);
      }

      if (data?.trascriptsCommitment) {
        completedDocs.push(WizardStep.Transcripts);
        // if the user completed the trascriptsCommitment, the Catalog and ACICS are also completed
        completedDocs.push(WizardStep.Catalog);
      }

      if (data?.enrollment) {
        completedDocs.push(WizardStep.Enrollment);
      }

      if (data?.factSheet) {
        completedDocs.push(WizardStep.FactSheet);
      }

      if (data?.vib) {
        completedDocs.push(WizardStep.VIB);
      }

      if (data?.hybridDisclosure) {
        completedDocs.push(WizardStep.HybridStatement);
      }

      if (data?.transcriptEvaluationRequest) {
        completedDocs.push(WizardStep.TranscriptEvaluation);
      }


      setCompletedSteps(completedDocs);
      setStudentName(data?.studentName);
      setIsVA(data?.vib !== 2); // 2 = not applicable

      if (data?.SDGKUprogram) {
        setIsHybrid(isHybridProgram(data?.SDGKUprogram));
      }
    }
  };

  const isHybridProgram = (program: number) => {
    let hybridValues = Object.entries(SDGKUProgram).filter(
      ([key, value]) => key.includes("_HYBRID") && value === program
    );
    return hybridValues.length > 0;
  }

  const getIcon = (step: WizardStep) => {
    if (inProgress === step) {
      return <ISpinner className="bullet-icon in-progress" />;
    } else if (completedSteps.includes(step)) {
      return <ICheck className="bullet-icon" />;
    } else {
      return <ICircle className="bullet-icon" />;
    }
  };

  const getStepClass = (step: WizardStep) => {
    if (inProgress === step) {
      return 'document-item in-progress';
    } else if (completedSteps.includes(step)) {
      return 'document-item completed';
    } else {
      return 'document-item';
    }
  };

  const isFormASimplePDF = (step: WizardStep) => step === WizardStep.Catalog;

  const isCompleted = () => completedSteps.length >= (isVA ? requiredSteps : requiredSteps - 1);

  const showForm = (step: WizardStep) => {
    if (isFormASimplePDF(step)) {
      addCompletedStep(step);
      setInProgress(WizardStep.None);
    } else {
      setInProgress(step);
    }
    setInProgressText(defaultInProgressText);

    let stepUrl = '';
    switch (step) {
      case WizardStep.FactSheet:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.FACTSHEET}${queryParams}&selfClose=true`;
        break;
      case WizardStep.VIB:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.VIB}${queryParams}&selfClose=true`;
        break;
      case WizardStep.Catalog:
        stepUrl = 'https://finaid360.com/api/redirect/20';
        break;
      case WizardStep.Transcripts:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.TRANSCRIPTS_COMMITMENT}${queryParams}&selfClose=true`;
        break;
      case WizardStep.Enrollment:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.ENROLLMENT_AGREEMENT}${queryParams}&selfClose=true`;
        break;
      case WizardStep.Disclosure:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.DISCLOSURE}${queryParams}&selfClose=true`;
        break;
      case WizardStep.HybridStatement:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.HYBRID_STATEMENT}${queryParams}&selfClose=true`;
        break;
      case WizardStep.TranscriptEvaluation:
        stepUrl = `/public/forms/SDGKU/${SignedFormKey.TRANSCRIPT_EVALUATION_REQUEST}${queryParams}&selfClose=true`;
        break;
    }

    // open form in new window
    const inProgressWindow = window.open(stepUrl, '_blank');

    if (!isFormASimplePDF(step)) {
      // check for child window close
      const checkInProgressWindow = setInterval(() => {
        if (inProgressWindow && inProgressWindow.closed) {
          // verify it the form was completed
          clearInterval(checkInProgressWindow);
          validateEnrollmentWasCompleted(step, 1);
          setInProgressText(' (Validating, please wait...)');
        }
      }, 300);
    }
  };

  const getFormItem = (step: WizardStep, text: string) => {
    const inProgressSpan = inProgress === step ? <span>{inProgressText}</span> : null;

    return (
      <li className={getStepClass(step)} onClick={() => showForm(step)}>
        {getIcon(step)}
        {text}
        {inProgressSpan}
      </li>
    );
  };

  if (tokenError) return <TokenError />;

  return (
    <div className="mx-auto p-3 doc-area enrollment-wizard">
      <Header />

      {completed ? (
        <WizardCompletedPage />
      ) : (
        <div className="my-5 wizard">
          <p className="applicant">Hello {studentName}</p>
          {/* <p>As part of your enrollment at San Diego Global Knowledge University, you are required to review, complete, and/or sign the following document(s).</p> */}

          <p className="howto-note">
            Please click on the document name to open it in a new tab. Once you complete the document, close the tab and return to this page to continue.
          </p>

          <ul className="document-list">
            {getFormItem(WizardStep.FactSheet, 'Fact Sheet')}

            {isVA && getFormItem(WizardStep.VIB, 'Veterans Information Bulletin')}

            {getFormItem(WizardStep.Catalog, 'General Catalog')}

            {getFormItem(WizardStep.Transcripts, 'Transcripts Commitment Letter')}

            {/* {getFormItem(WizardStep.Enrollment, 'Enrollment Agreement')} */}

            {getFormItem(WizardStep.Disclosure, 'Enrollment Disclosure Acknowledgement')}

            {isVA && isHybrid && getFormItem(WizardStep.HybridStatement, 'Hybrid Statement Of Understanding')}

            {isVA && getFormItem(WizardStep.TranscriptEvaluation, 'Transcript Evaluation Request')}
          </ul>

          {showTryNote && (
            <p className="important-note">
              <strong>Note:</strong> If you completed a form and it's not automatically validated, please try refreshing the page.
            </p>
          )}

          {isCompleted() && (
            <div className="text-center mt-6">
              <button className="btn btn-primary" onClick={() => setCompleted(true)}>
                Continue
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EnrollmentWizard;
